import React, { useState, useEffect } from "react";
import { FaSearch } from 'react-icons/fa';
import toast from "react-hot-toast";
import InfiniteScroll from "react-infinite-scroll-component";
import * as Common from "../../Components/Common";
import { getAllUsers } from "../../Services/usersService";
import { MESSAGES } from "../../Constants/Strings";
import { UserColumns } from "../../Constants/TableHeader";

const ListUsers = () => {
    const [userData, setUserData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({
        count: 0,
        offset: 0,
        limit: 10,
    });
    const [searchTerm, setSearchTerm] = useState("");
    const [filterCategory, setFilterCategory] = useState('');

    const tableColumns = [
        "first_name",
        "last_name",
        "email",
        "role",
    ];

    const roles = [
        { label: "Filter by Role", value: "", disabled: true },
        { label: "All", value: "ALL", disabled: false },
        { label: "Admin", value: "ADMIN", disabled: false },
        { label: "Customer", value: "CUSTOMER", disabled: false }
    ];

    const updatePagination = (count, reset = false) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            count: count,
            offset: reset ? pagination.limit : pagination.offset + pagination.limit,
        }));
    };

    const fetchAllUsers = (searchTerm, filterCategory, reset = false) => {
        if (!reset && pagination.offset > pagination.count) {
            setLoading(false);
            return;
        }
        const params = {
            limit: pagination.limit,
            offset: reset ? 0 : pagination.offset,
            search: searchTerm,
        };

        if (filterCategory !== "ALL") {
            params.role = filterCategory;
        }

        getAllUsers(params)
            .then((response) => {
                if (response.status === 200) {
                    const usersData = response.data.results;
                    const users = Array.isArray(usersData) ? usersData : [usersData];
                    setUserData((users));
                    updatePagination(response.data, reset);
                } else {
                    toast.error(MESSAGES.GENERAL_ERROR);
                }
            })
            .catch(() => {
                toast.error(MESSAGES.GENERAL_FETCH_ERROR);
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        if (searchTerm.length >= 3 || filterCategory !== "") {
            setLoading(true);
            setPagination({ count: 0, offset: 0, limit: 10 });
            fetchAllUsers(searchTerm, filterCategory, true);
        } else if (searchTerm.length === 0) {
            setUserData([]);
            setPagination({ count: 0, offset: 0, limit: 10 });
            fetchAllUsers("", filterCategory, true);
        }
    }, [searchTerm, filterCategory]);

    return (
        <div className="flex flex-col">
            <div className="flex justify-between items-center mt-20 md:mx-9">
                <div className="text-2xl md:text-3xl ml-6 md:ml-0 text-secondary font-semibold">
                    All Users
                </div>
            </div>

            {/* Search Bar and Sorting Dropdown */}
            <div className="flex flex-col md:flex-row justify-center md:justify-between gap-2 md:gap-4 items-start md:items-center ml-4 mt-2 md:mx-9">
                {/* Search Bar */}
                <div className="flex items-center bg-white border p-0.5 focus-within:border-blue-500 focus-within:border-2 border-gray-300 rounded-md w-auto md:w-1/3">
                    <FaSearch className="text-gray-400 ml-2" />
                    <input
                        type="text"
                        placeholder="Search by First Name/Email"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="w-full border-none focus:ring-white rounded-md"
                    />
                </div>
                {/* Sort Dropdown */}
                <div className="md:relative w-auto md:w-auto">
                    <select
                        value={filterCategory}
                        onChange={(e) => setFilterCategory(e.target.value)}
                        className="border text-gray-500 border-gray-300 p-2 rounded-md w-60"
                    >
                        {roles.map((role, index) => (
                            <option key={index} value={role.value} disabled={role.disabled}>
                                {role.label}
                            </option>
                        ))}
                    </select>
                </div>
            </div>

            <div className="mx-8">
                {loading ? (
                    <Common.Spinner />
                ) : (
                    <InfiniteScroll
                        dataLength={userData.length}
                        next={fetchAllUsers}
                        hasMore={pagination.offset <= pagination.count}
                        scrollableTarget="scrollableDiv"
                    >
                        <div
                            className="md:h-table_desk mt-4 md:overflow-x-auto md:overflow-y-auto"
                            id="scrollableDiv"
                        >
                            <Common.CommonTable
                                className="border bg-white"
                                tableColumns={tableColumns}
                                tableRows={userData}
                                columnLabels={UserColumns}
                            />
                        </div>
                    </InfiniteScroll>
                )}
            </div>
        </div>
    );
};

export default ListUsers;
