import Cookies from "js-cookie";

export const setUser = (user) => {
  setCookie("user", user, 1, true);
};

export const getInitials = (email) => {
  if (!email) return '';
  const emailPrefix = email?.split('@')?.[0];  
  const namesArray = emailPrefix?.split('.') || []; 
  const initials = namesArray.map(name => name?.charAt(0)?.toUpperCase() || '');
  return initials.join('');
};

const setCookie = (key, value, expiresInDays, isJson = false) => {
  if (isJson) {
    value = JSON.stringify(value);
  }
  Cookies.set(key, value, { expires: expiresInDays, sameSite: "Strict" });
};

export const removeCookie = (key) => {
  Cookies.remove(key);
};

export const getUser = () => {
  return getCookie("user", true);
};

export const getCookie = (key, isJson) => {
  let value = Cookies.get(key);
  if (isJson && value) {
    return JSON.parse(value);
  }
  return value;
};

export const formatDate = (dateString) => {
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    timeZone: "Asia/Kolkata",
  };
  return new Date(dateString).toLocaleString("en-US", options);
};
