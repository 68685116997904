import React, { useEffect, useRef } from 'react';
import ReactDOMServer from 'react-dom/server';
import { OrderPrintCard } from '../../Pages/Orders/OrdersComponent';

const OrderPrintTicket = ({ orderPrintIframeId, orderItemList, orderId, businessDetails }) => {
    const iframeRef = useRef(null);

    useEffect(() => {
        const iframeDocument = iframeRef.current?.contentWindow.document;
        if (iframeDocument) {
            iframeDocument.open();
            iframeDocument.write(
                ReactDOMServer.renderToString(
                    <OrderPrintCard
                        orderId={orderId}
                        businessDetails={businessDetails}
                        orderItemList={orderItemList}
                    />
                )
            );
            iframeDocument.close();
        }
    }, [orderItemList, orderId, businessDetails]);

    return (
        <iframe
            id={orderPrintIframeId}
            ref={iframeRef}
            title="Print"
            src="about:blank"
            className="hidden"
        />
    );
};

export default OrderPrintTicket;


