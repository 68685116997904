export const AdminOrders = [
  "order_code",
  "business_name",
  "contact_person_name",
  "contact_person_mobile_no",
  "created",
  "setup_category",
  "status",
  "actions",
];

export const UserOrders = [
  "business_name",
  "contact_person_name",
  "contact_person_mobile_no",
  "created",
  "setup_category",
  "status",
  "actions",
];
export const OrderColumns = {
  order_code:"Sr.No",
  business_name: "Business Name",
  contact_person_name: "Person Name",
  contact_person_mobile_no: "Person Mobile Number",
  address: "Address",
  setup_category: "Business Type",
  created: "Order Date",
  updated: "Modified by time",
  status: "Status",
  actions: "Actions"
};

export const UserColumns = {
  first_name: "First Name",
  last_name: "Last Name",
  email: "Email",
  role: "Role",
};

export const MasterLabels = {
  name: "Name",
  description: "Description",
  category: "Category",
  details: "Details",
  actions:"Actions"
}
