import React from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import * as signupService from "../../../Services/loginService";
import { ROLES, MESSAGES } from "../../../Constants/Strings";
import * as routes from "../../../routes";

const SignUpForm = () => {
  const navigate = useNavigate();
  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    first_name: Yup.string()
      .required(MESSAGES.REQUIRED)
      .min(2)
      .max(50)
      .matches(/^[a-zA-Z]+$/, MESSAGES.FIRST_NAME_VALIDATION),
    last_name: Yup.string()
      .required(MESSAGES.REQUIRED)
      .min(2)
      .max(50)
      .matches(/^[a-zA-Z]+$/, MESSAGES.LAST_NAME_VALIDATION),
    email: Yup.string()
      .email(MESSAGES.INVALID_EMAIL)
      .required(MESSAGES.REQUIRED)
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.com$/,
        MESSAGES.EMAIL_VALIDATION
      ),
    password: Yup.string()
      .min(5, MESSAGES.PASSWORD_VALIDATION)
      .required(MESSAGES.REQUIRED),
  });
  const navigateToLogin = () => {
    navigate(routes.LOGIN);
  };

  const onSubmit = (values, { setSubmitting }) => {
    values = {
      ...values,
      role: ROLES.CUSTOMER,
    };
    signupService
      .signup(values)
      .then((response) => {
        if (response.status === 201) {
          toast.success(MESSAGES.SIGN_UP_SUCCESS);
          navigateToLogin();
        } else {
          toast.error(MESSAGES.SIGN_UP_FAILURE);
        }
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <div className="p-4">
            <div className="flex flex-col gap-1">
            <h1 className="text-4xl md:text-5xl lg:text-5xl text-center font-bold leading-relaxed drop-shadow-lg uppercase text-rose-900">
              HORECA
            </h1>
            <h1 className="mb-2 text-xl md:text-2xl  text-center font-bold  leading-relaxed tracking-tight  text-sky-800">
              Create a new account
            </h1>
            </div>

            <Form className="space-y-4 md:space-y-1 w-full">
              <div className="flex flex-col gap-1">
                <div>
                  <label
                    htmlFor="first_name"
                    className="block text-sm font-semibold leading-6 text-gray-900"
                  >
                    First Name
                  </label>
                  <div className="mt-2">
                    <Field
                      type="text"
                      name="first_name"
                      id="first_name"
                      placeholder="Vivek"
                      required
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 bg-gray-100  focus:outline-none focus:shadow-outline"
                    />
                    <ErrorMessage
                      name="first_name"
                      component="div"
                      className="text-red-500 text-xs mt-1"
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="last_name"
                    className="block text-sm font-semibold leading-6 text-gray-900"
                  >
                    Last Name
                  </label>
                  <div className="mt-2">
                    <Field
                      type="text"
                      name="last_name"
                      id="last_name"
                      placeholder="Patil"
                      required
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 bg-gray-100  focus:outline-none focus:shadow-outline"
                    />
                    <ErrorMessage
                      name="last_name"
                      component="div"
                      className="text-red-500 text-xs mt-1"
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-semibold leading-6 text-gray-900"
                  >
                    Email address
                  </label>
                  <div className="mt-2">
                    <Field
                      type="email"
                      name="email"
                      id="email"
                      autoComplete="email"
                      placeholder="user@example.com"
                      required
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 bg-gray-100  focus:outline-none focus:shadow-outline"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-red-500 text-xs mt-1"
                    />
                  </div>
                </div>
                <div>
                  <div className="flex items-center justify-between">
                    <label
                      htmlFor="password"
                      className="block text-sm font-semibold leading-6 text-gray-900"
                    >
                      Password
                    </label>
                  </div>
                  <div className="mt-2">
                    <Field
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      placeholder="************"
                      required
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 bg-gray-100  focus:outline-none focus:shadow-outline"
                    />
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="text-red-500 text-xs mt-1"
                    />
                  </div>
                </div>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="w-full font-semibold mt-4 bg-blue-500 hover:bg-blue-700 text-white p-2 rounded-lg 
                                shadow-lg focus:outline-none focus:shadow-outline focus:ring-2 focus:ring-blue-300"
                >
                  Sign Up
                </button>
                <div className="flex items-center gap-2 justify-center text-center mt-2 text-sm text-secondary">
                  <div> Already a member?</div>
                  <div
                    className="font-bold text-md leading-6 text-secondary cursor-pointer"
                    onClick={navigateToLogin}
                  >
                    Login Now!
                  </div>
                </div>
              </div>
            </Form>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default SignUpForm;
