import React from "react";
import { Link } from "react-router-dom";
import { HOME } from "../routes";
import { AiOutlineHome } from "react-icons/ai";

const PageNotfound = () => {
  return (
    <div className="flex flex-col gap-4 text-gray-600 justify-center items-center h-[80vh]">
      <div>
        <p className="text-8xl font-extrabold">404</p>
        <p className="text-2xl font-semibold">Page not found!</p>
      </div>
      <div>
        <Link to={HOME}>
          <div className="px-4 py-2 rounded-md bg-black text-white">
            <p className="flex items-center gap-2 text-sm">
              <AiOutlineHome size={20} />
              Take me Home
            </p>
          </div>
        </Link>
      </div>
    </div>
  );
};
export default PageNotfound;
