import React, { useEffect, useState } from "react";
import Select from "react-select";
import toast from "react-hot-toast";
import { RiSave3Fill, RiDeleteBin5Fill } from "react-icons/ri";
import { MESSAGES } from "../../Constants/Strings";
import { reactSelectClass, reactSelectStyles, inputClass } from "../../Constants/CssConstant";
import { REACT_SELECT_SEPARATOR } from "../../Constants/FormConstants";

const AddItemRows = ({
  itemIndex,
  itemData,
  deleteItem,
  saveItem,
  itemList,
  purposeList,
  isEditable,
}) => {
  const [data, setData] = useState(itemData);
  const [disableSave, setDisableSave] = useState(!isEditable);
  const isDisabled = false;

  const [errors, setErrors] = useState({
    name: false,
    quantity: false,
    details: false,
    comment: false,
  });

  const itemOptions = itemList.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  const purposeOptions = purposeList.map((purpose) => ({
    value: purpose.id,
    label: purpose.details,
  }));

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
    setErrors({ ...errors, [name]: !value });
  };

  const handleSelectChange = (name) => (selectedOption) => {
    setData({ ...data, [name]: selectedOption ? selectedOption.value : "" });
  };

  const isRowEmpty = !data.name || !data.quantity || !data.details;
  const isDeleteDisabled = !isEditable || isRowEmpty;

  const handleSave = () => {
    if (isRowEmpty) {
      toast.error(MESSAGES.ERROR_ADDING_ROW);
    } else {
      saveItem(itemIndex, data);
      setDisableSave(true)
    }
  };

  const handleDelete = () => {
    if (!isDeleteDisabled) {
      deleteItem(itemIndex);
    }
  };

  useEffect(() => {
    setData(itemData);
  }, [itemData]);

  return (
    <div className="flex flex-col md:flex-row items-start p-4 md:items-center space-y-4 md:space-y-0 md:gap-6 ">
      <div className="w-full md:w-[150%]">
        <Select
          name="name"
          value={itemOptions.find((item) => item.value === data?.name?.id) || null}
          onChange={handleSelectChange('name')}
          isDisabled={!isEditable || isDisabled}
          options={itemOptions}
          styles={reactSelectStyles}
          placeholder="Item"
          className={`${reactSelectClass} ${errors.name ? "border-red-500" : ""}`}
          menuPortalTarget={document.body}
          components={REACT_SELECT_SEPARATOR}
        />
      </div>
      <input
        type="number"
        placeholder="Qty"
        name="quantity"
        value={data.quantity}
        disabled={!isEditable}
        onChange={(e) => e?.target?.value && handleChange(e)}
        className={`${inputClass} w-full md:w-[40%] ${errors.quantity ? "border-red-500" : ""}`}
      />
      <div className="w-full md:w-[130%]">
        <Select
          name="details"
          value={purposeOptions.find((purpose) => purpose.value === data?.details?.id) || null}
          onChange={handleSelectChange('details')}
          isDisabled={!isEditable || isDisabled}
          options={purposeOptions}
          styles={reactSelectStyles}
          placeholder="Purpose"
          className={`${reactSelectClass} ${errors.name ? "border-red-500" : ""}`}
          menuPortalTarget={document.body}
          components={REACT_SELECT_SEPARATOR}
        />
      </div>
      <input
        type="text"
        placeholder="Comments"
        name="comment"
        value={data.comment}
        disabled={!isEditable}
        onChange={(e) => {
          handleChange(e);
        }}
        className={`${inputClass} w-full md:w-[80%] ${errors.comment ? "border-red-500" : ""}`}
      />
      <div className="flex flex-row-reverse md:flex-row w-full md:w-auto items-center gap-4 md:space-x-4">
        <RiSave3Fill
          onClick={disableSave ? null : handleSave}
          className={`text-green-500 text-2xl cursor-pointer hover:text-green-600 transition duration-300 
            ${disableSave && "cursor-not-allowed opacity-100"
            }`}
        />
        <RiDeleteBin5Fill
          onClick={handleDelete}
          className={`text-red-500 text-2xl cursor-pointer hover:text-red-600 transition duration-300 
            ${isDeleteDisabled && "cursor-not-allowed opacity-100"
            }`}
        />
      </div>
    </div >
  );
};

export default AddItemRows;
