import React from "react";
import { Route, Routes } from "react-router-dom";
import { AuthPages, Orders, PageNotfound, AdminOrders, Master, Users } from "./Pages";
import { Layout, Common } from "./Components";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { UserContextProvider } from "./Context";
import * as routes from "./routes";
import { Toaster } from "react-hot-toast";

try {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_URL,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });
} catch (error) {
  console.log("Ignore: Sentry init issue!");
}

function App() {
  return (
    <>
      <Toaster
        position="top-right"
        reverseOrder={false}
        toastOptions={{
          loading: {
            icon: <Common.Spinner size={4} />,
          },
        }}
      />
      <UserContextProvider>
        <Routes>
          <Route path={routes.HOME} element={<Layout.PublicLayout />}>
            <Route path={routes.HOME} element={<AuthPages.Login />} />
          </Route>

          <Route path={routes.MASTER} element={<Layout.Layout />}>
            <Route path={routes.MASTER} element={<Master.MasterSection />} />
          </Route>

          <Route path={routes.LIST_USERS} element={<Layout.Layout />}>
            <Route path={routes.LIST_USERS} element={<Users.ListUsers />} />
          </Route>

          <Route path={routes.LIST_ORDERS} element={<Layout.Layout />}>
            <Route path={routes.LIST_ORDERS} element={<Orders.ListOrders />} />
          </Route>

          <Route path={routes.CREATE_ORDERS} element={<Layout.Layout />}>
            <Route path={routes.CREATE_ORDERS} element={<Orders.CreateOrder />} />
          </Route>

          <Route path={routes.MASTER} element={<Layout.Layout />}>
            <Route path={routes.MASTER} element={<Master.MasterSection/>} />
          </Route>

          <Route path={routes.ALL_ORDERS} element={<Layout.Layout />}>
            <Route path={routes.ALL_ORDERS} element={<AdminOrders.ListOrders />} />
          </Route>

          <Route path={routes.LOGIN} element={<Layout.PublicLayout />}>
            <Route path={routes.LOGIN} element={<AuthPages.Login />} />
          </Route>

          <Route path={routes.SIGNUP} element={<Layout.PublicLayout />}>
            <Route path={routes.SIGNUP} element={<AuthPages.SignUp />} />
          </Route>

          <Route path="*" element={<PageNotfound />} />
        </Routes>
      </UserContextProvider>
    </>
  );
}

export default App;
