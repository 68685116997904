import toast from "react-hot-toast";
import * as Common from "../../../Components/Common";
import * as formContansts from "../../../Constants/FormConstants";
import { useEffect, useState } from "react";
import {
  getBusinessCategories,
  getOrdersById,
  updateOrder,
} from "../../../Services/ordersService";
import { MESSAGES } from "../../../Constants/Strings";

const BusinessDetails = ({ orderId, setBusinessInfo }) => {
  const [readOnly, setReadOnly] = useState(false);
  const [allBusinessCategories, setAllBusinessCategories] = useState([]);
  const [showEdit, setShowEdit] = useState(true);
  const [showSave, setShowSave] = useState(false);
  const [formOrderData, setFormOrderData] = useState({
    business_name: "",
    address: "",
    contact_person_name: "",
    contact_person_mobile_no: "",
    owner_name: "",
    owner_mobile_no: "",
    setup_category: "",
    business_category: "",
  });

  let BUSINESS_FORM_FIELDS = {
    business_name: { type: "TEXT" },
    address: { type: "TEXT" },
    contact_person_name: { type: "TEXT" },
    contact_person_mobile_no: { type: "TEXT" },
    setup_category: { type: "RADIO", values: ["NEW", "OLD"] },
    owner_name: { type: "TEXT" },
    owner_mobile_no: { type: "TEXT" },
    business_category: {
      type: "SELECT_OBJECT",
      dropdownValues: allBusinessCategories,
      idCol: "id",
      displayCol: "category",
    },
  };

  const fieldTouched = {
    business_name: false,
    address: false,
    contact_person_name: false,
    contact_person_mobile_no: false,
    owner_mobile_no: false,
    owner_name: false,
    setup_category: false,
    business_category: false,
  };

  const toggleVisibility = () => {
    setReadOnly(false);
    setShowEdit(false);
    setShowSave(true);
  };

  const showEditButton = () => {
    setReadOnly(true);
    setShowEdit(true);
    setShowSave(false);
  };

  const formatDataForUpdate = (data) => {
    let formattedData = { ...data };
    Object.keys(BUSINESS_FORM_FIELDS).forEach((key) => {
      if (BUSINESS_FORM_FIELDS[key].type === "SELECT_OBJECT")
        formattedData[key] = data[key].id;
    });
    return formattedData;
  };

  const handleUpdate = (data) => {
    let formattedData = formatDataForUpdate(data);
    updateOrder(formattedData, orderId)
      .then((response) => {
        if (response.status === 200) {
          toast.success(MESSAGES.ORDER_UPDATE_SUCCESS);
        }
      })
      .catch((error) => {
        toast.error(MESSAGES.ORDER_UPDATE_ERROR);
      })
      .finally(() => {
        showEditButton();
      });
  };

  const handleFormData = (data) => {
    let newValues = {};
    for (let property in formOrderData) {
      if (data.hasOwnProperty(property)) {
        newValues[property] = data[property];
      }
    }
    setFormOrderData(newValues);
  };

  const handleError = (data) => {
    let error = {};
    const mobilePattern = /^\d{10}$/;
    for (let property in data) {
      switch (property) {
        case "contact_person_mobile_no":
          if (!mobilePattern.test(data[property])) {
            error[property] = "Mobile number must be valid";
          } else {
            delete error.contact_person_mobile_no;
          }
          break;
        case "owner_mobile_no":
          if (!mobilePattern.test(data[property])) {
            error[property] = "Mobile number must be valid";
          } else {
            delete error.owner_mobile_no;
          }
          break;
        default:
          if (
            typeof data[property] != "object" &&
            data[property].trim() === ""
          ) {
            error[property] = "must contain at least 1 character";
          } else {
            delete error[property];
          }
      }
    }
    return error;
  };

  const fetchAllBusinessCategories = () => {
    getBusinessCategories()
      .then((response) => {
        setAllBusinessCategories(response.data.results);
      })
      .catch((error) => {
        toast.error(MESSAGES.GENERAL_FETCH_ERROR);
      });
  };

  const getOrderDetails = (id) => {
    getOrdersById(id)
      .then((res) => {
        if (res.status === 200) {
          handleFormData(res.data);
          setBusinessInfo(res.data)
          showEditButton();
        }
      })
      .catch((err) => {
        toast.error(MESSAGES.ORDER_FETCH_ERROR);
      });
  };

  useEffect(() => {
    getOrderDetails(orderId);
    fetchAllBusinessCategories();
  }, [orderId]);

  return (
    <div className="flex flex-col gap-10">
      <Common.GenericForm
        formData={formOrderData}
        renderField={BUSINESS_FORM_FIELDS}
        formFieldLabels={formContansts.BUSINESS_FORM_LABELS}
        formTitle={formContansts.FORM_TITLE}
        formFieldTouched={fieldTouched}
        isReadOnly={readOnly}
        handleError={(data) => handleError(data)}
        handleSave={handleUpdate}
        handleEdit={toggleVisibility}
        showEditButton={showEdit}
        showSaveButton={showSave}
      />
    </div>
  );
};

export default BusinessDetails;
