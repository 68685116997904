import client from "./axiosConfig";

export const getOrders = (params) => {
  const config = {
    url: "/orders/",
    method: "GET",
    params,
  };
  return client(config);
};

export const getOrdersById = (id) => {
  const config = {
    url: `/orders/${id}/`,
    method: "GET",
  };
  return client(config);
};

export const getBusinessCategories = () => {
  const config = {
    url: `/master/businessCategory/`,
    method: "GET",
  };
  return client(config);
};

export const createOrder = (values) => {
  const config = {
    url: "/orders/",
    method: "POST",
    data: values,
  };
  const response = client(config);
  return response;
};

export const updateOrder = (id, data) => {
  const config = {
    url: `/orders/${id}/`,
    method: "PATCH",
    data,
  };
  const response = client(config);
  return response;
};

export const addOrderItems = (orderId, itemData) => {
  const config = {
    url: `/orders/${orderId}/item/`,
    method: "POST",
    data: itemData,
  };
  const response = client(config);
  return response;
};

export const deleteOrderItems = (orderId, itemId) => {
  const config = {
    url: `/orders/${orderId}/item/${itemId}/`,
    method: "DELETE",
  };
  const response = client(config);
  return response;
};

export const getOrderDetails = (orderId) => {
  const config = {
    url: `/orders/${orderId}/item/`,
    method: "GET",
    // params
  };
  return client(config);
};

export const getItemList = (params) => {
  const config = {
    url: "/master/item/",
    method: "GET",
    params,
  };
  return client(config);
};


export const getPurposeList = () => {
  const config = {
    url: "/master/purpose/",
    method: "GET",
  };
  return client(config);
};

export const createMasterEntity = (entity, values) => {
  const config = {
    url: `/master/${entity}/`,
    method: "POST",
    data: values,
  };
  return client(config);
};


export const getMasterList = (activeTab, params) => {
  const config = {
    url: `/master/${activeTab}/`,
    method: "GET",
    params,
  };
  return client(config);
};

export const editItem = (itemId, rowData) => {
  const config = {
    url: `/master/item/${itemId}/`,
    method: "PATCH",
    data: rowData,
  };
  return client(config);
};

export const deleteItem = (itemId) => {
  const config = {
    url: `/master/item/${itemId}/`,
    method: "DELETE",
  };
  return client(config);
};

export const editBusinessCategory = (itemId, rowData) => {
  const config = {
    url: `/master/businessCategory/${itemId}/`,
    method: "PATCH",
    data: rowData,
  };
  return client(config);
};

export const deleteBusinessCategory = (businessCategory_id) => {
  const config = {
    url: `/master/businessCategory/${businessCategory_id}/`,
    method: "DELETE",
  };
  return client(config);
};

export const editPurpose = (itemId, rowData) => {
  const config = {
    url: `/master/purpose/${itemId}/`,
    method: "PATCH",
    data: rowData,
  };
  return client(config);
};
export const deletePurpose = (Purpose_id) => {
  const config = {
    url: `/master/purpose/${Purpose_id}/`,
    method: "DELETE",
  };
  return client(config);
};
