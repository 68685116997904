import { useEffect, useState } from "react";
import { Common } from "../../../Components";
import { MasterLabels } from "../../../Constants/TableHeader";
import InfiniteScroll from "react-infinite-scroll-component";
import * as purposeService from "../../../Services/ordersService";
import toast from "react-hot-toast";
import { RiDeleteBin5Fill, RiEdit2Fill } from "react-icons/ri";
import { MESSAGES } from "../../../Constants/Strings";
import {actions, renderField, menus } from "../../../Constants/MasterConstants";

const PurposeManager = () => {
  const [purposeId, setPurposeId] = useState(0);
  const [purposes, setPurposes] = useState([]);
  const [isOpen, setIsOpen] = useState({ status: false, action: "" });
  const [loading, setLoading] = useState(false);

  const [pagination, setPagination] = useState({
    count: 0,
    offset: 0,
    limit: 10,
  });
  const [formInitialData, setFormInitialData] = useState({
    details: "",
  });
  const fieldTouched = {
    details: false,
  };

  const tableColumns = menus.purpose["tableColumns"];

  const renderFields = (key, rowData, entity_Id) => {
    
    if (key === "actions") {
      return (
        <div className="flex w-full gap-4 items-center">
          <RiEdit2Fill
            className={
              "text-primary text-2xl cursor-pointer hover:text-secondary transition duration-300"
            }
            onClick={() => {
              openPopUp(actions.edit, entity_Id, rowData);
            }}
          />

          <RiDeleteBin5Fill
            className={
              "text-red-500 text-2xl cursor-pointer hover:text-red-600 transition duration-300"
            }
            onClick={() => {
              openPopUp(actions.delete, entity_Id);
            }}
          />
        </div>
      );
    } 
    return rowData[key];;
  };

  const openPopUp = (action, id = 0, rowData = {}) => {
    setPurposeId(id);
    switch (action) {
      case actions.add:
        setFormInitialData({
          details: "",
        });
        setIsOpen({ status: true, action: action });
        break;
      case actions.edit:
        setFormInitialData({
          details: rowData.details,
        });
        setIsOpen({ status: true, action: action });
        break;
      case actions.delete:
        setFormInitialData({});
        setIsOpen({ status: true, action: action });
        break;
      default:
        break;
    }
  };

  const updatePagination = (count) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      count: count,
      offset: pagination.offset + pagination.limit,
    }));
  };

  const updateEntity = (new_entity) => {
    setPurposes((prevData) => {
      const combinedEntity = [...prevData, ...new_entity];
      const uniqueEntity = Array.from(
        new Map(combinedEntity.map((entity) => [entity.id, entity])).values()
      );

      return uniqueEntity;
    });
  };
  
  const fetchEntity = (activeTab) => {
    if (pagination.offset > pagination.count) {
      setLoading(false);
      return;
    }

    purposeService
      .getMasterList(activeTab, pagination)
      .then((response) => {
        if (response.status === 200) {
          updatePagination(response.data.count);
          updateEntity(response.data.results);
        } else {
          toast.error(MESSAGES.FETCH_PURPOSE_FAILURE);
        }
      })
      .catch(() => {
        toast.error(MESSAGES.FETCH_PURPOSE_ERROR);
      })
      .finally(() => setLoading(false));
  };

  const handleError = (data) => {
    return {};
  };

  const onsubmit = (values) => {

    switch (isOpen.action) {
      case actions.add:
        purposeService
        .createMasterEntity(menus.purpose["url"], values)
        .then((res) => {
          if (res.status === 201) {
            toast.success(MESSAGES.PURPOSE_ADD_SUCCESS);
            fetchEntity(menus.purpose["url"]);
          }
        })
        .catch((err) => {
          toast.error(MESSAGES.PURPOSE_ADD_ERROR);
        });
        break;
      case actions.edit:
        purposeService
        .editPurpose(purposeId, values)
        .then((res) => {
          if (res.status === 200) {
            toast.success(MESSAGES.PURPOSE_EDIT_SUCCESS);
            setPagination({
              count: 0,
              offset: 0,
              limit: 10,
            });
            fetchEntity(menus.purpose["url"]);
          }
        })
        .catch((err) => {
          toast.error(MESSAGES.PURPOSE_EDIT_ERROR);
        });
        break;
      case actions.delete:
        purposeService
        .deletePurpose(purposeId)
        .then((res) => {
          if (res.status === 204) {
            toast.success(MESSAGES.PURPOSE_DELETE_SUCCESS);
            setPurposes([]);
            
            setPagination({
              count: 0,
              offset: 0,
              limit: 10,
            });
            fetchEntity(menus.purpose["url"]);
          } else if (res.status === 500) {
            
            toast.error(MESSAGES.PURPOSE_BOOKED_ERROR);
          }
        })
        .catch((err) => {
          toast.error(MESSAGES.PURPOSE_DELETE_ERROR);
        });
        break;
    
      default:
        break;
    }
    setIsOpen({ status: false, action: "" });
  };

  const btnTitle = () => {

    switch (isOpen.action) {
      case actions.edit:
        return { btnTitle: "Save", message: "" };
        
      case actions.delete:
        return {
          btnTitle: "Delete",
          message: MESSAGES.PURPOSE_DELETE_CONFORMATION,
        };
    
      default:
        break;
    }
  };

  useEffect(() => {
    setLoading(true);
    setPagination({
      count: 0,
      offset: 0,
      limit: 10,
    });
    fetchEntity(menus.purpose["url"]);
  }, [isOpen]);

  return (
    <div className="flex flex-col gap-4 mt-4 w-full md:w-4/5 lg:w-4/5 h-max md:h-[85vh] lg:h-[85vh] p-6 bg-white shadow-md">
      <div className="w-full flex  items-center justify-between ">
        <h2 className="text-secondary font-bold text-2xl">
          {menus.purpose["name"]}
        </h2>
        <Common.Button
          className="max-w-max bg-primary hover:bg-secondary"
          onClick={() => {openPopUp("Add");}}
        >
          Add {menus.purpose["name"]}
        </Common.Button>
      </div>
      {isOpen.status && (
        <div className="">
          <Common.GenericPopUpForm
            formData={formInitialData}
            renderField={renderField}
            formFieldLabels={MasterLabels}
            formTitle={isOpen.action + " " + menus.purpose["name"]}
            formFieldTouched={fieldTouched}
            onClose={() => {setIsOpen({ status: false, action: "" });}}
            isOpen={isOpen.status}
            handleData={(values) => {onsubmit(values);}}
            handleError={(data) => handleError(data)}
            className={{
              buttonClass: "mt-4 w-full flex justify-end",
              mainContainerclass:
                "p-8 bg-white rounded-md shadow-lg relative min-h-min",
              formclass: "p-2 flex flex-col w-full",
              inputclass: "w-full",
              cutBtnClass:
                "absolute top-0 right-0 m-4 text-xl cursor-pointer focus:outline-none",
            }}
            formContent={btnTitle()}
          />
        </div>
      )}

      {loading ? (
        <Common.Spinner />
      ) : (
        <InfiniteScroll
          dataLength={purposes.length}
          next={() => {fetchEntity(menus.purpose["url"]);}}
          hasMore={pagination.offset <= pagination.count}
          scrollableTarget="scrollableDiv"
        >
          <div
            className="h-table_desk overflow-y-scroll mt-2 w-full"
            id="scrollableDiv"
          >
            <Common.CommonTable
              className="border-2 bg-white"
              tableColumns={tableColumns}
              tableRows={purposes}
              columnLabels={MasterLabels}
              renderField={renderFields}
            />
          </div>
        </InfiniteScroll>
      )}
    </div>
  );
};

export default PurposeManager;
