import client from "./axiosConfig";

export const getAllUsers = (params) => {
    const config = {
        url: "/users/allusers",
        method: "GET",
        params: params,
    };
    
    return client(config);
};