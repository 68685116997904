import React, { useContext } from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import * as Context from "../../Context/UserContext";
import { ALL_ORDERS, LIST_ORDERS, LOGIN, SIGNUP } from "../../routes";
import { ROLES } from "../../Constants/Strings";

const PublicLayout = () => {
  const Public_Routes = [LOGIN, SIGNUP];
  const { user } = useContext(Context.UserContext);
  const location = useLocation();
  const isLoggedIn = Boolean(user);

  //if user is loggedin and we enter the routes login and signup then it will stay on /orders
  if (isLoggedIn && Public_Routes.indexOf(location.pathname) !== -1) {
    if (user?.role === ROLES.ADMIN) {
      return <Navigate to={ALL_ORDERS} />;
    } else if (user?.role === ROLES.CUSTOMER) {
      return <Navigate to={LIST_ORDERS} />;
    }
  }
  if (!isLoggedIn && Public_Routes.indexOf(location.pathname) === -1) {
    return <Navigate to={LOGIN} />;
  }

  return (
    <div>
      <main className="bg-slate-100 ">
        <div className="min-h-public_outlet desktop:min-h-public_outlet_desk">
          <Outlet />
        </div>
      </main>
    </div>
  );
};

export default PublicLayout;
