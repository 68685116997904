export const ROLES = {
  CUSTOMER: "CUSTOMER",
  ADMIN:"ADMIN"
};

export const STATUS = [
  { key: "In Progress", value: "In Progress" },
  { key: "Accepted", value: "Accepted" },
  { key: "Out for Delivery", value: "Out for Delivery" },
  { key: "Completed", value: "Completed" },
  { key: "Cancelled", value: "Cancelled" },
  { key: "Failed", value: "Failed" }
];

export const MESSAGES = {
  SIGN_UP_SUCCESS: "Sign up successfull. Please Login!",
  SIGN_UP_FAILURE: "User already exists , Kindly Login !",
  LOGIN_SUCCESS: "Login successfull",
  LOGIN_FAILURE: "Incorrect email or password",
  GENERAL_ERROR: "Failed to load orders",
  GENERAL_FETCH_ERROR: "An error occurred while fetching orders",
  ORDER_CREATE_SUCCESS: "Order created successfully!",
  ORDER_CREATE_FAILURE: "Failed to create order",
  ORDER_FETCH_ERROR: "Failed to fetch data",
  ORDER_UPDATE_ERROR: "Failed to update data",
  ORDER_UPDATE_SUCCESS: "Order updated successfully",
  ORDER_RELOAD_PAGE: "Please reload the Page !",
  ORDER_ITEM_SUCCESS: "Item added successfully",
  ORDER_ITEM_FAILURE: "Failed to add Items",
  DELETE_ITEM_SUCCESS: "Item deleted successfully",
  DELETE_ITEM_FAILURE: "Failed to delete Items",
  ERROR_LOADING_DETAILS: "Error loading Order Details",
  ERROR_ADDING_ROW: "New Row already added ,\n Please fill all the fields !",
  NO_DATA: "No Data !",
  INVALID_EMAIL: "Invalid email",
  EMAIL_VALIDATION: "Incorrect Email",
  PASSWORD_VALIDATION: "Password must be at least 6 characters",
  REQUIRED: "Required",
  SOMETHING_WENT_WRONG: "Something went Wrong",
  FIRST_NAME_VALIDATION: "First name must contain only letters",
  LAST_NAME_VALIDATION: "Last name must contain only letters",

  //items
  FETCH_ITEM_FAILURE: "Failed to fetch item list",
  FETCH_ITEM_ERROR: "Error fetching item list:",


  ITEM_ADD_SUCCESS: "Item added successfully.",
  ITEM_ADD_ERROR: "Failed to add item.",

  ITEM_CREATE_SUCCESS: "Item created successfully.",
  ITEM_CREATE_ERROR: "Failed to create item.",

  ITEM_EDIT_ERROR: "Error updating item.",
  ITEM_EDIT_SUCCESS: "Item updated successfully.",

  ITEM_DELETE_ERROR: "Failed to delete item.",
  ITEM_DELETE_SUCCESS: "Item deleted successfully.",

  ITEM_BOOKED_ERROR:"Cannot delete item due to an existing placed order.",

  ITEM_DELETE_CONFORMATION:"Are you sure you want to delete this item ?",


  //businessCategory
  FETCH_BUSINESS_CATEGORY_FAILURE: "Failed to fetch business categories.",
  FETCH_BUSINESS_CATEGORY_ERROR: "Error fetching business categories:",

  BUSINESS_CATEGORY_ADD_SUCCESS: "Business category added successfully.",
  BUSINESS_CATEGORY_ADD_ERROR: "Failed to add business category.",

  BUSINESS_CATEGORY_CREATE_SUCCESS: "Business category created successfully.",
  BUSINESS_CATEGORY_CREATE_ERROR: "Failed to create business category.",

  BUSINESS_CATEGORY_EDIT_ERROR: "Error updating business category.",
  BUSINESS_CATEGORY_EDIT_SUCCESS: "Business category updated successfully.",

  BUSINESS_CATEGORY_DELETE_ERROR: "Failed to delete business category.",
  BUSINESS_CATEGORY_DELETE_SUCCESS: "Business category deleted successfully.",

  BUSINESS_CATEGORY_BOOKED_ERROR:"Cannot delete Business Category due to an existing placed order.",
  BUSINESS_CATEGORY_DELETE_CONFORMATION:"Are you sure you want to delete this Business Category ?",
  //purpose
  FETCH_PURPOSE_FAILURE: "Failed to fetch purposes.",
  FETCH_PURPOSE_ERROR: "Error fetching purposes:",

  PURPOSE_ADD_SUCCESS: "Purpose added successfully.",
  PURPOSE_ADD_ERROR: "Failed to add purpose.",

  PURPOSE_CREATE_SUCCESS: "Purpose created successfully.",
  PURPOSE_CREATE_ERROR: "Failed to create purpose.",

  PURPOSE_EDIT_ERROR: "Error updating purpose.",
  PURPOSE_EDIT_SUCCESS: "Purpose updated successfully.",

  PURPOSE_DELETE_ERROR: "Failed to delete purpose.",
  PURPOSE_DELETE_SUCCESS: "Purpose deleted successfully.",

  PURPOSE_BOOKED_ERROR:"Cannot delete purpose due to an existing placed order.",
  PURPOSE_DELETE_CONFORMATION:"Are you sure you want to delete this purpose ?",

  //Order Status
  ORDER_STATUS_SUCCESS: "Order Status updated Succesfully",
  ORDER_STATUS_FAILURE: "Failed to update Order Status",

  REQUEST_ERROR: "Failed to load requests",

};
