
const SideBar = ({
  menus,
  activeTab,
  setActivetab,
}) => {
  const handelclick = (TabTitle) => {
    setActivetab(TabTitle);
  };
  return (
    <>
      <div className="flex flex-row md:flex-col lg:flex-col md:w-1/5 lg:w-1/5 w-full gap-6 mt-4 p-6 md:h-[85vh] lg:h-[85vh] h-max bg-white shadow-md">
        {Object.entries(menus).map(([key, value]) => (
          <div
            key={key} 
            className={`p-4 border-b border-gray-100 cursor-pointer hover:bg-gray-100 ${
              activeTab === key ? "bg-gray-200 font-bold hover:bg-gray-200" : "" 
            }`}
            onClick={() => handelclick(key)}
          >
            {value.name}
          </div>
        ))}
      </div>
    </>
  );
};
export default SideBar;
