
const Button = ({ children, onClick, disabled = false, className, ...rest }) => {
    const defaultClasses = "flex gap-2 justify-center items-center text-white bg-blue-500 hover:bg-blue-700 disabled:bg-gray-400 font-medium rounded-md text-sm px-3 py-2";
    const classes = ` ${className} ${defaultClasses}`;
    return (
        <button onClick={onClick} disabled={disabled} className={classes} {...rest}>
            {children}
        </button>
    );
};


export default Button