import { useEffect, useState } from "react";
import { Common } from "../../../Components";
import { MasterLabels } from "../../../Constants/TableHeader";
import { RiDeleteBin5Fill, RiEdit2Fill } from "react-icons/ri";
import * as itemService from "../../../Services/ordersService";
import toast from "react-hot-toast";
import InfiniteScroll from "react-infinite-scroll-component";
import { MESSAGES } from "../../../Constants/Strings";
import {actions, renderField,menus } from "../../../Constants/MasterConstants";

const ItemManager = () => {
  const [isOpen, setIsOpen] = useState({ status: false, action: "" });
  const [entity, setEntity] = useState([]);
  const [loading, setLoading] = useState(false);
  const [itemId, setItemId] = useState(0);

  //for form data
  const [formInitialData, setFormInitialData] = useState({
    name: "",
    description: "",
  });
  const [pagination, setPagination] = useState({
    count: 0,
    offset: 0,
    limit: 10,
  });

  const fieldTouched = {
    name: false,
    description: false,
    actions: false,
  };

  //   for table data
  const tableColumns = menus.item["tableColumns"];

  const renderFields = (key, rowData, entity_Id) => {
    if (key === "actions") {
      return (
        <div className="flex w-full gap-4 items-center">
          <RiEdit2Fill
            className={
              "text-primary text-2xl cursor-pointer hover:text-secondary transition duration-300"
            }
            onClick={() => {
              openPopUp(actions.edit, entity_Id, rowData);
            }}
          />

          <RiDeleteBin5Fill
            className={
              "text-red-500 text-2xl cursor-pointer hover:text-red-600 transition duration-300"
            }
            onClick={() => {
              openPopUp(actions.delete, entity_Id);
            }}
          />
        </div>
      );
    } 
    return rowData[key];
  };

  const updatePagination = (count) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      count: count,
      offset: pagination.offset + pagination.limit,
    }));
  };

  const updateEntity = (new_entity) => {
    setEntity((prevData) => {
      const combinedEntity = [...prevData, ...new_entity];
      const uniqueEntity = Array.from(
        new Map(combinedEntity.map((entity) => [entity.id, entity])).values()
      );

      return uniqueEntity;
    });
  };

  const fetchEntity = (menuEntity) => {
    if (pagination.offset > pagination.count) {
      setLoading(false);
      return;
    }

    itemService.getMasterList(menuEntity, pagination)
      .then((response) => {
        if (response.status === 200) {
          updatePagination(response.data.count);
          updateEntity(response.data.results);
        } else {
          toast.error(MESSAGES.FETCH_ITEM_FAILURE);
        }
      })
      .catch(() => {
        toast.error(MESSAGES.FETCH_ITEM_ERROR);
      })
      .finally(() => setLoading(false));
  };

  const openPopUp = (action, id = 0, rowData = {}) => {
    setItemId(id);
    switch (action) {
      case actions.add:
        setFormInitialData({
          name: "",
          description: "",
        });
        break;
      case actions.edit:
        setFormInitialData({
          name: rowData.name,
          description: rowData.description,
        });
        break;
      case actions.delete:
        setFormInitialData({});
        break;
    
      default:
        break;
    }
    setIsOpen({ status: true, action: action });
  };

  const handleError = (data) => {
    return {};
  };

  const onsubmit = (values) => {
    switch (isOpen.action) {
      case actions.add:
        itemService.createMasterEntity(menus.item["url"], values)
        .then((res) => {
          if (res.status === 201) {
            toast.success(MESSAGES.ITEM_ADD_SUCCESS);
            setIsOpen({ status: false, action: "" });
            fetchEntity(menus.item["url"]);
          }
        })
        .catch((err) => {
          toast.error(MESSAGES.ITEM_ADD_ERROR);
        });
        break;
      case actions.edit:
        itemService.editItem(itemId, values)
        .then((res) => {
          if (res.status === 200) {
            toast.success(MESSAGES.ITEM_EDIT_SUCCESS);
            setPagination({
              count: 0,
              offset: 0,
              limit: 10,
            });
            fetchEntity(menus.item["url"]);
          }
          setIsOpen({ status: false, action: "" });
        })
        .catch((err) => {
          toast.error(MESSAGES.ITEM_EDIT_ERROR);
        });
        break;
      case actions.delete:
        itemService.deleteItem(itemId)
        .then((res) => {
          if (res.status === 204) {
            toast.success(MESSAGES.ITEM_DELETE_SUCCESS);
            setEntity([]);
            setPagination({
              count: 0,
              offset: 0,
              limit: 10,
            });
            fetchEntity(menus.item["url"]);
          }
          else if (res.status === 500) {
            toast.error(MESSAGES.ITEM_BOOKED_ERROR);
          }
          setIsOpen({ status: false, action: "" });
        })
        .catch((err) => {
          toast.error(MESSAGES.ITEM_DELETE_ERROR);
          setIsOpen({ status: false, action: "" });
        });
        break;
      default:
        break;
    }

  };

  const btnTitle = () => {
    switch (isOpen.action) {
      case actions.edit:
        return {btnTitle:"Save",message:""};
        
      case actions.delete:
        return {btnTitle:"Delete",message:MESSAGES.ITEM_DELETE_CONFORMATION};
        
      default:
        break;
    }
  };

  useEffect(() => {
    setLoading(true);
    setPagination({
      count: 0,
      offset: 0,
      limit: 10,
    });
    fetchEntity(menus.item["url"]);
  }, [isOpen]);

  return (
    <>
      <div className="flex flex-col w-full md:w-4/5 lg:w-4/5  h-max md:h-[85vh] lg:h-[85vh] gap-4 mt-4  p-6 bg-white shadow-md md:">
        <div className="w-full flex items-center justify-between">
          <h2 className="text-secondary font-bold text-2xl">{menus.item["name"]}</h2>
          <Common.Button
            className="max-w-max bg-primary hover:bg-secondary"
            onClick={() => {openPopUp(actions.add);}}
          >
            {actions.add} {menus.item["name"]}
          </Common.Button>
        </div>
        {isOpen.status && (
          <div className="">
            <Common.GenericPopUpForm
              formData={formInitialData}
              renderField={renderField}
              formFieldLabels={MasterLabels}
              formTitle={isOpen.action+" " + menus.item["name"]}
              formFieldTouched={fieldTouched}
              onClose={() => {setIsOpen({ status: false, action: "" });}}
              isOpen={isOpen.status}
              handleData={(values) => {onsubmit(values);}}
              handleError={(data) => handleError(data)}
              className={{
                buttonClass: "mt-4 w-full flex justify-end",
                mainContainerclass:
                  "p-8 bg-white rounded-md shadow-lg relative min-h-min",
                formclass: "p-2 flex flex-col w-full",
                inputclass: "w-full",
                cutBtnClass:
                  "absolute top-0 right-0 m-4 text-xl cursor-pointer focus:outline-none",
              }}
              formContent={btnTitle()}
            />
          </div>
        )}

        {loading ? (
          <Common.Spinner />
        ) : (
          <InfiniteScroll
            dataLength={entity.length}
            next={() => {fetchEntity(menus.item["url"]);}}
            hasMore={pagination.offset <= pagination.count}
            scrollableTarget="scrollableDiv"
          >
            <div className="h-table_desk overflow-y-scroll mt-2" id="scrollableDiv">
              <Common.CommonTable
                className="border-2 bg-white"
                tableColumns={tableColumns}
                tableRows={entity}
                columnLabels={MasterLabels}
                renderField={renderFields}
              />
            </div>
          </InfiniteScroll>
        )}
      </div>
    </>
  );
};
export default ItemManager;
