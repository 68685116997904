import React, { useState, useEffect } from 'react'
import { FaSearch, FaRegEdit } from 'react-icons/fa';
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import * as Common from "../../Components/Common"
import * as routes from "../../routes";
import * as OrdersList from "../../Constants/OrderListConstants";
import * as orderService from "../../Services/ordersService";
import * as formConstants from "../../Constants/FormConstants"
import { AdminOrders, OrderColumns } from "../../Constants/TableHeader";
import { formatDate } from "../../Utils";
import { MESSAGES, STATUS } from "../../Constants/Strings";

const TotalOrders = () => {
  const [orderData, setOrderData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [currentId, setCurrentId] = useState("");
  const [pagination, setPagination] = useState({
    count: 0,
    offset: 0,
    limit: 10,
  });
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [filterCategory, setFilterCategory] = useState("");
  const [formInitialData, setFormInitialData] = useState({
    status: "",
  });

  const setupCategories = [
    { label: "Filter by Setup Category", key: "", disabled: true },
    { label: "All", key: "ALL", disabled: false },
    { label: "New", key: "NEW", disabled: false },
    { label: "Old", key: "OLD", disabled: false }
  ];

  let ORDER_FORM_FIELDS = {
    status: {
      type: "SELECT",
      dropdownValues: STATUS.map(status => status.key),
    },
  };

  const fieldTouched = {
    status: false,
  };

  const openPopUp = (orderId) => {
    setCurrentId(orderId)
    setIsOpen(true);
  };

  const handleError = () => {
    return {}
  };

  const onSubmit = (obj) => {
    if (currentId) {
      orderService.updateOrder(currentId, obj)
        .then((response) => {
          if (response.status === 200) {
            toast.success(MESSAGES.ORDER_STATUS_SUCCESS);
            const orderToUpdate = orderData.find(order => order.id === currentId);
            if (orderToUpdate) {
              const updatedOrder = processOrders([{ ...orderToUpdate, obj }]);
              setOrderData((prevData) =>
                prevData.map(order =>
                  order.id === currentId ? updatedOrder[0] : order
                )
              );
            }
            setIsOpen(false);
            fetchAllOrders(searchTerm, filterCategory, true);
          } else {
            toast.error(MESSAGES.ORDER_STATUS_FAILURE);
          }
        })
        .catch(() => {
          toast.error(MESSAGES.GENERAL_FETCH_ERROR);
        });
    }
  };

  const renderFields = (key, orderData, orderId) => {
    const value = orderData[key];
    if (key === OrdersList.KEYS.action) {
      return (
        <div className='flex items-center gap-4'>
          <button
            className="font-semibold text-primary py-3 hover:text-secondary"
            onClick={() => {
              navigate(routes.CREATE_ORDERS.replace(":orderId", orderId), {
                state: { isEditable: false },
              });
            }}
          >
            {OrdersList.BUTTON_TITLE}
          </button>
          <button
            className="flex items-center gap-1 font-semibold text-blue-400 py-3 hover:text-secondary"
            onClick={() => openPopUp(orderId)}
          >
            <span className="text-lg"><FaRegEdit /></span>Edit
          </button>
        </div>
      );
    }
    return value;
  };

  const processOrders = (orders) => {
    return orders.map((order) => ({
      ...order,
      created: formatDate(order.created),
      updated: formatDate(order.updated),
    }));
  };

  const updateOrderData = (orders, reset = false) => {
    setOrderData((prevData) => {
      if (reset) {
        return orders;
      } else {
        const combinedOrders = [...prevData, ...orders];
        const uniqueOrders = Array.from(
          new Map(combinedOrders.map((order) => [order.id, order])).values()
        );
        return uniqueOrders;
      }
    });
  };

  const updatePagination = (response, reset = false) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      count: response.data.count,
      offset: reset ? pagination.limit : pagination.offset + pagination.limit,
    }));
  };

  const fetchAllOrders = (searchTerm, filterCategory, reset = false) => {
    if (!reset && pagination.offset > pagination.count) {
      setLoading(false);
      return;
    }
    const params = {
      limit: pagination.limit,
      offset: reset ? 0 : pagination.offset,
      search: searchTerm,
    };
    if (filterCategory !== "ALL") {
      params.setup_category = filterCategory;
    }
    orderService.getOrders(params)
      .then((response) => {
        if (response.status === 200) {
          const orders = processOrders(response.data.results);
          updateOrderData(orders, reset);
          updatePagination(response, reset);
        } else {
          toast.error(MESSAGES.GENERAL_ERROR);
        }
      })
      .catch(() => {
        toast.error(MESSAGES.GENERAL_FETCH_ERROR);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (searchTerm.length >= 3 || filterCategory !== "") {
      setLoading(true);
      setPagination({ count: 0, offset: 0, limit: 10 });
      fetchAllOrders(searchTerm, filterCategory, true);
    } else if (searchTerm.length === 0) {
      setOrderData([]);
      setPagination({ count: 0, offset: 0, limit: 10 });
      fetchAllOrders("", filterCategory, true);
    }
  }, [searchTerm, filterCategory]);

  return (
    <div className="">
      <div className="flex justify-between items-center mt-20 md:mx-9">
        <div className="text-2xl md:text-3xl ml-6 md:ml-0 text-secondary font-semibold">
          All Orders
        </div>
      </div>
      {/* Search Bar and Sorting Dropdown */}
      <div className="flex flex-col md:flex-row justify-center md:justify-between gap-2 md:gap-4 items-start md:items-center ml-4 mt-2 md:mx-9">
        {/* Search Bar */}
        <div className="flex items-center bg-white border p-0.5 focus-within:border-blue-500 focus-within:border-2 border-gray-300 rounded-md w-auto md:w-1/3">
          <FaSearch className="text-gray-400 ml-2" />
          <input
            type="text"
            placeholder="Search by Business Name or Mobile Number"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className= "w-full border-none focus:ring-white rounded-md"
          />
        </div>
        {/* Sort Dropdown */}
        <div className="md:relative w-auto md:w-auto">
          <select
            value={filterCategory}
            onChange={(e) => setFilterCategory(e.target.value)}
            className="border text-gray-500 border-gray-300 p-2 rounded-md w-60"
          >
            {setupCategories.map((category, index) => (
              <option key={index} value={category.key} disabled={category.disabled}>
                {category.label}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="">
        {isOpen && (
          <Common.GenericPopUpForm
            formData={formInitialData}
            renderField={ORDER_FORM_FIELDS}
            formFieldLabels={formConstants.ORDER_FORM_LABELS}
            formTitle={formConstants.ORDER_FORM_TITLE}
            formFieldTouched={fieldTouched}
            className={{
              buttonClass: "mt-4 w-full flex justify-end",
              mainContainerclass:
                "w-full md:w-1/3 p-12 bg-white rounded-md shadow-lg relative",
              formclass: "flex flex-col w-full",
              inputclass: "w-full",
              cutBtnClass:
                "absolute top-0 right-0 m-4 text-xl cursor-pointer focus:outline-none",
            }}
            onClose={() => {
              setIsOpen(false);
            }}
            isOpen={isOpen}
            handleData={onSubmit}
            handleError={handleError}
          />
        )}
      </div>

      <div className="mx-8">
        {loading ? (
          <Common.Spinner />
        ) : (
          <InfiniteScroll
            dataLength={orderData.length}
            next={() => fetchAllOrders(searchTerm, filterCategory)}
            hasMore={pagination.offset < pagination.count}
            scrollableTarget="scrollableDiv"
          >
            <div
              className="md:h-table_desk mt-4 md:overflow-x-auto lg:overflow-y-auto"
              id="scrollableDiv"
            >
              <Common.CommonTable
                className="border bg-white"
                tableColumns={AdminOrders}
                tableRows={orderData}
                columnLabels={OrderColumns}
                renderField={renderFields}
              />
            </div>
          </InfiniteScroll>
        )}
      </div>
    </div>
  )
}

export default TotalOrders
