import { useNavigate } from "react-router-dom";
import * as Context from "../../Context/UserContext";
import { useContext } from "react";
import logoutimg from "../../Assets/logout.png";
import { LOGIN } from "../../routes";

const Logout = ({ isMenuOpen }) => {
  const { logout } = useContext(Context.UserContext);
  const navigate = useNavigate();
  const doLogout = () => {
    logout();
    navigate(LOGIN);
  };

  return (
    <button
      onClick={doLogout}
      className={`w-full font-semibold md:border-0 rounded focus:outline-none ${
        isMenuOpen ? "text-Red-500 md:hidden" : "hidden md:block"
      }`}
    >
      {isMenuOpen ? (
        <div className="flex justify-start items-center py-2 px-4 text-red-500 hover:bg-gray-50  dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent w-full">
          Logout
        </div>
      ) : (
        <img src={logoutimg} alt="Logout Icon" className=" w-8 h-8 " />
      )}
    </button>
  );
};

export default Logout;
