import React from "react";
import bgImage from "../../Assets/login-page-bg.jpg";
import { LoginForm } from "./AuthComponents";

const Login = () => {
  return (
    <div className="flex">
      <div className="w-3/5 h-full hidden lg:block relative">
        <img className="w-full h-screen" src={bgImage} alt="background" />
      </div>
      <div className="w-full lg:w-2/5 bg-cover bg-center bg-login-page">
        <LoginForm />
      </div>
    </div>
  );
};

export default Login;
