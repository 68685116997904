import React, { useContext, useState } from "react";
import { Outlet, useLocation, Navigate } from "react-router-dom";
import { Header, Footer } from "./index";
import * as Context from "../../Context/UserContext";
import { LOGIN, SIGNUP, LIST_ORDERS, ALL_ORDERS } from "../../routes";
import { ROLES } from "../../Constants/Strings";

const Layout = ({ children }) => {
  const Public_Routes = [LOGIN, SIGNUP];
  const { user } = useContext(Context.UserContext);
  const location = useLocation();
  const isLoggedIn = Boolean(user);

  if (isLoggedIn && Public_Routes.indexOf(location.pathname) !== -1) {
    if (user?.role === ROLES.ADMIN) {
      return <Navigate to={ALL_ORDERS} />;
    } else if (user?.role === ROLES.CUSTOMER) {
      return <Navigate to={LIST_ORDERS} />;
    }
  }

  if (!isLoggedIn && Public_Routes.indexOf(location.pathname) === -1) {
    return <Navigate to={LOGIN} />;
  }

  return (
    <React.Fragment>
      <div>
        <div className="fixed h-header_desk w-screen">
          <Header />
        </div>
        <main className="min-h-screen_m md:min-h-screen md:h-screen_desk overflow-auto bg-slate-100">
          <Outlet />
        </main>
        <div className="h-footer_m md:h-footer_desk">
          <Footer />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Layout;
