import { useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { FaPrint } from "react-icons/fa"
import ItemList from "./ItemList";
import { BusinessDetails } from "./OrdersComponent";
import { OrderPrintTicket } from "../../Components/Common";

const CreateOrder = () => {
  const params = useParams();
  const { state } = useLocation();
  const [orderId, setOrderId] = useState(params.orderId);
  const [orderItemList, setOrderItemList] = useState([]);
  const [businessDetails, setBusinessDetails] = useState([]);
  const orderPrintIframeId = "order-iframe";

  const printOrderticket = (orderPrintIframeId) => {
    const iframe = window.frames[orderPrintIframeId].contentWindow;
    if (iframe) {
      iframe.focus();
      iframe.print();
    }
  };

  return (
    <div className="p-4 flex flex-col gap-3 md:flex-row md:justify-around md:p-3">
      <div className=" mt-16 md:w-[30%]">
        <BusinessDetails
          orderId={orderId}
          isEditable={state.isEditable}
          setBusinessInfo={setBusinessDetails} />
      </div>
      {/* Order Item Table */}
      <div className="w-full flex flex-col items-end">
        <div className="w-full md:mt-16">
          <ItemList
            orderId={orderId}
            isEditable={state.isEditable}
            setOrderItems={setOrderItemList}
          />
        </div>

        <div className="mt-4">
          <button
            onClick={() => printOrderticket(orderPrintIframeId)}
            className="flex items-center gap-2 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
          >
            <FaPrint />
            <p>Print</p>
          </button>
        </div>
      </div>

      {/* Hidden iframe for KOT content */}
      <OrderPrintTicket
        orderPrintIframeId={orderPrintIframeId}
        orderId={orderId}
        businessDetails={businessDetails}
        orderItemList={orderItemList} />
    </div>
  );
};

export default CreateOrder;
