export const navLinkClass = `block py-2 px-4 text-gray-700 border-b border-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-white md:p-0 dark:text-gray-400 
  md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700`;

export const reactSelectClass = "react-select-wrap w-full rounded focus:outline-none disabled:opacity-100 bg-white shadow-md";

export const inputClass = "px-4 py-2 border border-gray-300 text-gray-500 rounded focus:outline-none focus:ring-1 focus:ring-blue-500 bg-white shadow-md"

export const reactSelectStyles = {
    input: (provided) => ({
        ...provided,
        '& input': {
            boxShadow: 'none !important',  // Specifically targets the input field inside
        },
    }),
};