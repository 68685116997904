import React, { useState, useContext, useEffect } from "react";
import { MdClose } from "react-icons/md";
import { AiOutlineMenu } from "react-icons/ai";
import Logout from "./Logout";
import logo from "../../Assets/crockery-logo.jpg";
import * as routes from "../../routes";
import * as Common from "../Common"
import * as Context from "../../Context";
import { Link, useLocation } from "react-router-dom";
import { ROLES } from "../../Constants/Strings";
import { navLinkClass } from "../../Constants/CssConstant";
import UserProfileBadge from "./UserProfileBadge";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { user } = useContext(Context.UserContext);
  const current_url = useLocation()
  const current_path = current_url.pathname;

  const getNavLinkClass = (path) => {
    return path.trim() === current_path.trim()
      ? `${navLinkClass} text-white font-semibold border-b-2 border-white/50`
      : navLinkClass;
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const userRoute = user?.role === ROLES.ADMIN ? routes.ALL_ORDERS : routes.LIST_ORDERS;

  useEffect(() => {
    setIsMenuOpen(false)
  }, [current_path])

  return (
    <>
      <div className={`${isMenuOpen ? 'block' : 'hidden'} h-[100vh] w-[100vw] bg-white/10 backdrop-blur-sm absolute z-10`} onClick={() => { setIsMenuOpen(false) }}></div>
      <nav className="flex bg-primary border-gray-200 h-16 dark:bg-gray-800 z-20 relative">
        <div className="container flex flex-wrap justify-between items-center mx-0 relative ">
          <Link to={userRoute} className="flex gap-2 items-center pl-4">
            <span className="w-12">
              <img src={logo} className="w-[100%]" alt="logo" />
            </span>
            <span className="self-center text-xl font-semibold text-gray-700 whitespace-nowrap dark:text-white">
              HORECA
            </span>
          </Link>
          <button
            data-collapse-toggle="mobile-menu"
            type="button"
            className="inline-flex items-center p-2 px-4 ml-3 text-sm text-gray-500 rounded-lg md:hidden"
            aria-controls="mobile-menu"
            aria-expanded={isMenuOpen}
            onClick={toggleMenu}
          >
            <span className="sr-only">Open main menu</span>
            {isMenuOpen ? (
              <MdClose className="w-6 h-6" />
            ) : (
              <AiOutlineMenu className="w-6 h-6" />
            )}
          </button>
          <div
            className={`${isMenuOpen ? "block " : "hidden "
              } w-full md:block md:w-auto bg-primary absolute md:static top-12 mt-3 md:mt-0 md:mr-3`}
            id="mobile-menu"
          >
            <ul className="flex flex-col md:flex-row md:justify-center md:items-center md:space-x-8 md:mt-0 md:font-medium ">
              <Common.PermissionValidator role={ROLES.CUSTOMER}>
                <li>
                  <Link
                    to={routes.LIST_ORDERS}
                    className={getNavLinkClass(routes.LIST_ORDERS)}
                    aria-current="page"

                  >
                    Orders
                  </Link>
                </li>
              </Common.PermissionValidator>
              <Common.PermissionValidator role={ROLES.ADMIN}>
                <li>
                  <Link
                    to={routes.ALL_ORDERS}
                    className={getNavLinkClass(routes.ALL_ORDERS)}
                    aria-current="page"
                  >
                    Orders
                  </Link>
                </li>
              </Common.PermissionValidator>
              <Common.PermissionValidator role={ROLES.ADMIN}>
                <li>
                  <Link
                    to={routes.MASTER}
                    className={getNavLinkClass(routes.MASTER)}
                    aria-current="page"
                  >
                    Master
                  </Link>
                </li>
              </Common.PermissionValidator>
              <Common.PermissionValidator role={ROLES.ADMIN}>
                <li>
                  <Link
                    to={routes.LIST_USERS}
                    className={getNavLinkClass(routes.LIST_USERS)}
                    aria-current="page"
                  >
                    Users
                  </Link>
                </li>
              </Common.PermissionValidator>
              <li>
                <Link
                  to={routes.LIST_ORDERS}
                  className={navLinkClass}
                >
                  About Us
                </Link>
              </li>
              <li>
                <Link
                  to={routes.LIST_ORDERS}
                  className={navLinkClass}
                >
                  Services
                </Link>
              </li>
              <li>
                <Link
                  to={routes.LIST_ORDERS}
                  className={navLinkClass}
                >
                  Contact Us
                </Link>
              </li>
              <li className="md:hidden flex justify-between p-3">
                <Logout isMenuOpen={isMenuOpen} />
                <UserProfileBadge email={user.email} />
              </li>
              <li className="hidden md:flex md:gap-4 items-center">
                <UserProfileBadge email={user.email} />
                <Logout isMenuOpen={false} />
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
