// formData =>  json of initial form data Key-DefaultValue pair
// renderField => json of key - value which declares input type(SELECT, DATE, TEXT) and input type meta info if needed e.g.  { "key": { "type": "SELECT", "dropdownValues": ["1", "2", "3"] } }
// handleData => get calls on save click
// changeMode => change mode to readOnly or Editable
// handleError => input validation
// formFieldTouched => json of input fiqed name and boolean to check if input is touched by user or not
// showEditButton => control visibility edit button
// showSaveButton => shows visibility of save button

import { useEffect, useState } from "react";

const GenericForm = ({
  formData,
  renderField,
  formFieldLabels = null,
  formTitle,
  formFieldTouched,
  isReadOnly,
  showEditButton,
  showSaveButton,
  handleError,
  handleSave,
  handleEdit,
}) => {
  const [updatedFormData, setUpdatedFormData] = useState(formData);
  const [isFieldTouch, setIsFieldTouch] = useState(formFieldTouched);
  const [error, setError] = useState({});

  const handleBlur = (e) => {
    const { name } = e.target;
    setIsFieldTouch({
      ...isFieldTouch,
      [name]: true,
    });
    let errors = handleError(updatedFormData);
    setError(errors);
  };

  const getIdForSelectedOjectOption = (optionList = []) => {
    for (let i = 0; i < optionList.length; i++) {
      if (optionList[i].selected) {
        return optionList[i].id;
      }
    }
  };

  const handleChange = (fieldName, e) => {
    let data = "";
    if (renderField[fieldName].type === "SELECT_OBJECT") {
      data = {
        id: getIdForSelectedOjectOption(e.target.options),
        category: e.target.value,
      };
    } else {
      data = e.target.value;
    }
    setIsFieldTouch({
      ...isFieldTouch,
      [e.target.name]: true,
    });

    setUpdatedFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: data,
    }));
    let errors = handleError(updatedFormData);
    setError(errors);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let errors = handleError(updatedFormData);
    if (Object.entries(errors).length === 0) {
      handleSave(updatedFormData);
    }
  };

  const checkObject = (fieldData) => {
    if (typeof fieldData === "object" && fieldData !== null) {
      return fieldData.category !== undefined ? fieldData.category : null; // Return category or null if not present
    } else {
      return fieldData; // Directly return fieldData if it's not an object
    }
  };

  useEffect(() => {
    setUpdatedFormData(formData);
  }, [formData]);

  const default_css = `flex mb-4 flex-col md:flex-row md:justify-between md:items-center`;
  const radio_css = `flex mb-4 gap-6 md:gap-0 md:justify-between`;

  return (
    <div className="bg-white p-6 rounded-md shadow-lg min-h-min max-h-[90vh] overflow-y-auto">
      <h2 className="text-2xl text-secondary font-bold mb-6 text-center">
        {formTitle}
      </h2>
      <form onSubmit={(e) => handleSubmit(e)} className="flex flex-col gap-3">
        <div className="flex justify-between flex-col gap-2 md:gap-2 ">
          {Object.entries(updatedFormData).map(([fieldName, fieldValue]) => (
            <div
              key={fieldName}
              className={
                renderField[fieldName].type === "RADIO"
                  ? radio_css
                  : default_css
              }
            >
              {renderField[fieldName].type === "HIDE" ? (
                <></>
              ) : (
                <label
                  htmlFor={fieldName}
                  className="block font-sm text-sm text-gray-900 mb-1 md:w-[40%]"
                >
                  {formFieldLabels && formFieldLabels[fieldName]
                    ? formFieldLabels[fieldName]
                    : fieldName}
                </label>
              )}
              {(() => {
                switch (renderField[fieldName].type) {
                  case "SELECT":
                    return (
                      <div>
                        <select
                          id={fieldName}
                          name={fieldName}
                          value={updatedFormData[fieldName]}
                          onChange={(e) =>
                            handleChange(fieldName, e.target.value)
                          }
                          required
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        >
                          <option value="" disabled>
                            Select
                          </option>
                          {renderField[fieldName].dropdownValues?.map(
                            (option, index) => (
                              <option key={index} value={option}>
                                {formFieldLabels && formFieldLabels[option]
                                  ? formFieldLabels[option]
                                  : option}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                    );
                  case "SELECT_OBJECT":
                    return (
                      <div className="md:w-[55%]">
                        <select
                          id={fieldName}
                          name={fieldName}
                          value={updatedFormData[fieldName].category}
                          onChange={(e) => handleChange(fieldName, e)}
                          disabled={isReadOnly}
                          required
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm 
                          rounded-lg focus:ring-blue-500 focus:border-blue-500 block 
                          w-full p-2.5 disabled:opacity-100"
                        >
                          <option value="">Select</option>
                          {renderField[fieldName].dropdownValues?.map(
                            (option, index) => {
                              return (
                                <option
                                  key={index}
                                  id={option[renderField[fieldName]["idCol"]]}
                                  value={
                                    option[renderField[fieldName]["displayCol"]]
                                  }
                                  defaultValue={
                                    option[
                                    renderField[fieldName]["displayCol"]
                                    ] ===
                                    updatedFormData[fieldName][
                                    renderField[fieldName]["displayCol"]
                                    ]
                                  }
                                >
                                  {option[renderField[fieldName]["displayCol"]]}
                                </option>
                              );
                            }
                          )}
                        </select>
                      </div>
                    );
                  case "RADIO":
                    return (
                      <div className="flex gap-3 md:w-[55%]">
                        {renderField[fieldName].values?.map((value, index) => (
                          <div className="flex items-center gap-1" key={index}>
                            <input
                              type="radio"
                              id={fieldName}
                              value={value}
                              name={fieldName}
                              checked={value === updatedFormData[fieldName]}
                              onChange={(e) => handleChange(fieldName, e)}
                              disabled={isReadOnly}
                            />
                            <p>{value}</p>
                          </div>
                        ))}
                      </div>
                    );
                  case "DATE":
                    return (
                      <div>
                        <input
                          type="date"
                          value={updatedFormData[fieldName]}
                          disabled={isReadOnly}
                          onChange={(e) => handleChange(fieldName, e)}
                          className="p-2 w-full text-md sm:text-sm text-gray-900 border border-gray-300 rounded-md bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
                        />
                      </div>
                    );
                  case "TEXT":
                    return (
                      <div className="md:w-[55%]">
                        <input
                          type="text"
                          id={fieldName}
                          name={fieldName}
                          value={checkObject(updatedFormData[fieldName])}
                          onBlur={(e) => {
                            handleBlur(e);
                          }}
                          onChange={(e) => handleChange(fieldName, e)}
                          required
                          disabled={isReadOnly}
                          className={`${isReadOnly
                              ? "bg-gray-50 border border-gray-100 text-gray-500 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                              : "bg-gray-50 border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            }`}
                        />
                        <p className="text-red-500">
                          {error && error[fieldName] ? error[fieldName] : ""}
                        </p>
                      </div>
                    );
                  case "DISABLED":
                    return (
                      <div>
                        <input
                          type="text"
                          id={fieldName}
                          name={fieldName}
                          disabled={true}
                          value={updatedFormData[fieldName]}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                          required
                        />
                      </div>
                    );
                  case "HIDE":
                    return null;
                  default:
                    return (
                      <p className="text-red-500 text-sm">
                        Form field input type is not defined
                      </p>
                    );
                }
              })()}
            </div>
          ))}
        </div>
        <div className="self-end">
          {showEditButton ? (
            <button
              onClick={handleEdit}
              className="bg-primary hover:bg-secondary text-white font-bold py-2 px-4 rounded-md focus:outline-none focus:ring focus:border-secondary w-full md:w-auto"
            >
              Edit
            </button>
          ) : (
            ""
          )}
          {showSaveButton && (
            <button
              type="submit"
              className="bg-primary hover:bg-secondary text-white font-bold py-2 px-4 rounded-md focus:outline-none focus:ring focus:border-secondary w-full md:w-auto"
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Save
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default GenericForm;
