import React from "react";
import { useState, useEffect, useContext } from "react";
import { IoArrowBack } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import * as ordersService from "../../Services/ordersService";
import * as Context from "../../Context";
import * as routes from "../../routes"
import AddItemRows from "./AddItemRows";
import { MESSAGES, ROLES } from "../../Constants/Strings";

const ItemList = ({ orderId, isEditable, setOrderItems }) => {
  const itemDetails = {
    id: "",
    created: "",
    updated: "",
    name: "",
    quantity: "",
    details: "",
    comment: "",
  };

  const [orderItemList, setOrderItemList] = useState([]);
  const [itemList, setItemList] = useState([]);
  const [purposeList, setPurposeList] = useState([]);
  const { user } = useContext(Context.UserContext);
  const navigate = useNavigate();

  const handleBackClick = () => {
    if (user.role === ROLES.ADMIN) {
      navigate(routes.ALL_ORDERS);
    } else if (user.role === ROLES.CUSTOMER) {
      navigate(routes.LIST_ORDERS);
    }
  };

  const addItemRows = (index, updatedData) => {
    if (orderId) {
      const itemData = updatedData;
      itemData.order = orderId;
      ordersService.addOrderItems(orderId, itemData).then((response) => {
        if (response.status === 200) {
          getOrderItems(orderId);
          setOrderItemList((prevOrderDetails) => {
            const updatedItems = [...prevOrderDetails];
            updatedItems[index] = updatedData;
            return updatedItems;
          });
          toast.success(MESSAGES.ORDER_ITEM_SUCCESS);
        } else {
          toast.error(MESSAGES.ORDER_ITEM_FAILURE);
        }
      });
    } else {
      toast.error(MESSAGES.ORDER_RELOAD_PAGE);
    }
  };

  const deleteItemRow = async (itemIndex) => {
    if (orderId) {
      const itemId = orderItemList[itemIndex].id;
      ordersService.deleteOrderItems(orderId, itemId).then((response) => {
        if (response.status === 204) {
          getOrderItems(orderId);
          toast.success(MESSAGES.DELETE_ITEM_SUCCESS);
        } else {
          toast.error(MESSAGES.DELETE_ITEM_FAILURE);
        }
      });
    } else {
      toast.error(MESSAGES.ORDER_RELOAD_PAGE);
    }
  };


  function getOrderItems(orderId) {
    ordersService
      .getOrderDetails(orderId)
      .then((response) => {
        setOrderItemList([...response.data.results, itemDetails]);
        setOrderItems([...response.data.results, itemDetails]);
      })
      .catch((error) => {
        toast.error(MESSAGES.ERROR_LOADING_DETAILS);
      });
  }

  const getItemListName = () => {
    ordersService
      .getItemList()
      .then((response) => {
        if (response.status === 200) {
          setItemList(response.data.results);
        } else {
          toast.error(MESSAGES.FETCH_ITEM_FAILURE);
        }
      })
      .catch((error) => {
        toast.error(MESSAGES.FETCH_ITEM_ERROR, error);
      });
  };

  const getPurposeListName = () => {
    ordersService
      .getPurposeList()
      .then((response) => {
        if (response.status === 200) {
          setPurposeList(response.data.results);
        } else {
          toast.error(MESSAGES.FETCH_ITEM_FAILURE);
        }
      })
      .catch((error) => {
        toast.error(MESSAGES.FETCH_ITEM_ERROR, error);
      });
  };

  useEffect(() => {
    if (orderId) {
      getOrderItems(orderId);
      getItemListName();
      getPurposeListName();
    }
  }, [orderId]);

  return (
    <div className="flex flex-col justify-between items-center bg-white px-7 py-5 rounded shadow-lg  min-h-min max-h-[80vh] overflow-y-auto">
      <div className="flex w-[100%] justify-between">
        <div className="text-2xl text-secondary font-bold ">Items List</div>
        <div>
          <button
            onClick={handleBackClick}
            className="p-2 px-4 flex items-center font-semibold rounded-md bg-primary text-white hover:text-secondary"
          >
            <IoArrowBack className="mr-2" />
            <p>Back</p>
          </button>
        </div>
      </div>
      <div className="bg-slate-100 flex flex-col-reverse gap-6 md:w-full md:flex-col items-center mt-4 shadow-lg p-4 rounded-lg">
        <div className="border-4 p-4">
          {orderItemList.length > 0 && (
            <div className="hidden md:flex md:w-full font-bold text-secondary ml-3 p-2">
              <div className="w-1/3  text-left">Name</div>
              <div className="w-1/4 mx-3 text-center">Quantity</div>
              <div className="w-1/3 text-left">Purpose</div>
              <div className="w-1/3 ml-5 text-left">Comment</div>
              <div className="w-1/5 text-left">Actions</div>
            </div>
          )}

          {orderItemList.map((item, index) => (
            <AddItemRows
              className="mt-2"
              key={index}
              itemIndex={index}
              itemData={item}
              itemList={itemList}
              purposeList={purposeList}
              saveItem={(index, data) => addItemRows(index, data)}
              deleteItem={(key) => deleteItemRow(key)}
              isEditable={isEditable}
            />
          ))}
          {orderItemList.length === 0 && <div>{MESSAGES.NO_DATA}</div>}
        </div>
      </div>
    </div>
  );
};

export default ItemList;
