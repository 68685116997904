import React from "react";

const CommonTable = ({
  tableColumns,
  tableRows,
  columnLabels = null,
  rowClick = null,
  headerClassName = null,
  renderField = null,
  className = null,
  fieldClick = null,
  ...rest
}) => {
  const defaultClasses = "w-full  text-left text-gray-500 ";
  const classes = `${defaultClasses} ${className}`;

  return (
    <>
      {/* Table view for larger screens */}
      <div className="hidden md:block border-none">
        <table className={classes} {...rest}>
          <thead
            className={`sticky -top-1 text-md  bg-gray-50 ${headerClassName}`}
          >
            <tr className="shadow-md">
              {tableColumns.map((key) => (
                <th scope="col" className="px-2 py-3" key={key}>
                  {columnLabels ? columnLabels[key] : key}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableRows?.map((row, rowIndex) => (
              <tr key={rowIndex} className="border-b">
                {tableColumns.map((key, index) => (
                  <td
                    key={key}
                    className="max-w-xs md:max-w-sm lg:max-w-lg px-2 py-2 whitespace-nowrap overflow-hidden text-ellipsis"
                  >
                    {renderField
                      ? renderField(key, row, tableRows[rowIndex].id)
                      : row[key]}
                  </td>
                ))}
              </tr>
            ))}
            {!tableRows || tableRows?.length === 0 ? (
              <tr>
                <td
                  className="text-lg text-center h-[62vh]"
                  colSpan={tableColumns.length}
                >
                  No data available!
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>

      {/* Mobile view */}
      <div className="block md:hidden space-y-4 p-4">
        {tableRows?.map((row, rowIndex) => (
          <div
            key={rowIndex}
            className="border rounded-md shadow-md p-4 bg-white cursor-pointer"
          >
            {tableColumns.map((key, index) => (
              <div key={key} className="flex justify-between py-2 items-center">
                <span className="font-medium text-gray-700">
                  {columnLabels ? columnLabels[key] : key}:
                </span>
                <span className="pl-2 text-gray-900 cursor-pointer">
                  {renderField
                    ? renderField(key, row, tableRows[rowIndex].id)
                    : row[key]}{" "}
                </span>
              </div>
            ))}
          </div>
        ))}
        {!tableRows || tableRows?.length === 0 ? (
          <div className="text-lg text-center h-full">No data available!</div>
        ) : null}
      </div>
    </>
  );
};

export default CommonTable;
