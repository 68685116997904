import React, { useState, useContext, useEffect } from "react";
import * as Context from "../../Context";

const PermissionValidator = ({ role, children}) => {
  const [isActionAllowed, setIsActionAllowed] = useState(false);
  const { user } = useContext(Context.UserContext);
  useEffect(() => {
    setIsActionAllowed(user.role === role);
  }, [user, role]);

  return (
    <>
      {isActionAllowed ? children : <></>}
    </>
  );
};

export default PermissionValidator;
