export const menus = {
    businessCategory: {
      name: "Business Category",
      url: "businessCategory",
      tableColumns: ["category", "description", "actions"],
      formFields: ["category", "description"],
    },
    item: {
      name: "Products",
      url: "item",
      tableColumns: ["name", "description", "actions"],
      formFields: ["name", "description"],
    },
    purpose: {
      name: "Purpose",
      url: "purpose",
      tableColumns: ["details", "actions"],
      formFields: ["details"],
    },
  };

export const renderField = {
  name: { type: "TEXT" },
  description: { type: "TEXTAREA" },
  category: { type: "TEXT" },
  details: { type: "TEXTAREA" },
};

export const actions = {
  add:"Add",
  edit:"Edit",
  delete:"Delete"
}