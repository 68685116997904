import React from "react";
import { SignUpForm } from "./AuthComponents";
import landingPage from "../../Assets/signup-page-bg.png";

const SignUp = () => {
  return (
    <div className="flex ">
      <div className="w-3/5 h-screen hidden lg:block">
        <img
          src={landingPage}
          alt="Agrawal Crockery World"
          className="w-full h-full"
        />
      </div>
      <div className="w-full lg:w-2/5 flex justify-center items-center text-left flex-col overflow-hidden p-4 shadow h-screen bg-center bg-cover bg-signup-page lg:bg-none">
        <div className="w-3/4 bg-white border border-gray-200 rounded-lg shadow-md">
          <div className=" p-2 space-y-2 mx-auto sm:w-full sm:max-w-md">
            <div className="flex flex-col gap-4 rounded-lg justify-center items-center">
              <div className="w-full">
                <SignUpForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
