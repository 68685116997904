import React from 'react';

const OrderPrintCard = ({ orderItemList, businessDetails }) => {

    return (
        <div>
            <style>{`
                body {
                    font-family: Arial, sans-serif;
                    font-size: 14px;
                    color: #333;
                    line-height: 1.6;
                    margin: 20px;
                }
                h1, h2 {
                    color: #333;
                    text-align: center;
                    margin-bottom: 20px;
                }
                h1 {
                    font-size: 24px;
                    font-weight: bold;
                }
                h2 {
                    font-size: 20px;
                    font-weight: bold;
                    border-bottom: 2px solid #ddd;
                    padding-bottom: 5px;
                    margin-bottom: 15px;
                }
                .grid-container {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    gap: 10px 20px;
                    margin-bottom: 20px;
                    padding: 10px;
                    border: 1px solid #ddd;
                    border-radius: 8px;
                    background-color: #f9f9f9;
                }
                .grid-item {
                    display: flex;
                    flex-direction: column;
                }
                .grid-item label {
                    font-weight: bold;
                    color: #555;
                    margin-bottom: 5px;
                    font-size: 14px;
                }
                .grid-item p {
                    font-size: 14px;
                    color: #333;
                    margin: 0;
                    padding: 0; /* Removed padding */
                    border: none; /* Removed border */
                    background-color: transparent; /* Removed background */
                }
                table {
                    width: 95%;
                    border-collapse: collapse;
                    margin: 20px auto;
                    table-layout: fixed;
                }
                th, td {
                    padding: 12px 15px;
                    border: 1px solid #dee2e6;
                    text-align: left;
                    word-wrap: break-word;
                }
                th {
                    background-color: #f5f5f5;
                    font-size: 16px;
                    color: #333;
                    font-weight: bold;
                    text-transform: uppercase;
                }
                tbody tr:nth-child(even) {
                    background-color: #f9f9f9;
                }
                tbody tr:hover {
                    background-color: #f1f1f1;
                }
                @media print {
                    body {
                        margin: 0;
                    }
                    h1, h2 {
                        color: #000;
                    }
                    .grid-item label {
                        color: #000;
                    }
                    th {
                        background-color: #e6e6e6;
                    }
                    table, th, td {
                        border-color: #000;
                    }
                }
            `}</style>

            <h1>Order Summary</h1>

            <h2>Business Details</h2>
            <div className="grid-container">
                <div className="grid-item">
                    <label>Business Name</label>
                    <p>{businessDetails?.business_name || 'N/A'}</p>
                </div>
                <div className="grid-item">
                    <label>Address</label>
                    <p>{businessDetails?.address || 'N/A'}</p>
                </div>
                <div className="grid-item">
                    <label>Person Name</label>
                    <p>{businessDetails?.contact_person_name || 'N/A'}</p>
                </div>
                <div className="grid-item">
                    <label>Person Mobile Number</label>
                    <p>{businessDetails?.contact_person_mobile_no || 'N/A'}</p>
                </div>
                <div className="grid-item">
                    <label>Owner Name</label>
                    <p>{businessDetails?.owner_name || 'N/A'}</p>
                </div>
                <div className="grid-item">
                    <label>Owner Mobile Number</label>
                    <p>{businessDetails?.owner_mobile_no || 'N/A'}</p>
                </div>
                <div className="grid-item">
                    <label>Business Category</label>
                    <p>{businessDetails?.business_category?.category || 'N/A'}</p>
                </div>
                <div className="grid-item">
                    <label>Business Type</label>
                    <p>{businessDetails?.setup_category || 'N/A'}</p>
                </div>
                <div className="grid-item">
                    <label>Order Date</label>
                    <p>{new Date(businessDetails?.created).toLocaleDateString() || 'N/A'}</p>
                </div>
                <div className="grid-item">
                    <label>Order Status</label>
                    <p>{businessDetails?.status || 'N/A'}</p>
                </div>
            </div>

            <h2>Items List</h2>
            <table>
                <thead>
                    <tr>
                        <th>Item Name</th>
                        <th>Quantity</th>
                        <th>Purpose</th>
                        <th>Comment</th>
                    </tr>
                </thead>
                <tbody>
                    {orderItemList
                        .filter((item) => item.name && item.quantity && item.details)
                        .map((item) => (
                            <tr key={item.id}>
                                <td>{item.name.name}</td>
                                <td>{item.quantity}</td>
                                <td>{item.details.details}</td>
                                <td>{item.comment}</td>
                            </tr>
                        ))}
                </tbody>
            </table>
        </div>
    );
};

export default OrderPrintCard;
