import client from "./axiosConfig";

const login = (values) => {
  const config = {
    url: "/auth/login/",
    method: "POST",
    data: values,
  };
  const response = client(config);
  return response;
};

const signup = (values) => {
  const config = {
    url: "/users/signup/",
    method: "POST",
    data: values,
  };
  const response = client(config);
  return response;
};

export { login, signup };
