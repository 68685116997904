import React from "react";
import { getInitials } from "../../Utils";

const UserProfileBadge = ({ email }) => {
    const initials = getInitials(email);

    return (
        <div className={`flex gap-2 items-center`}>
            <div className="flex items-center gap-2">
                <div className="w-fit px-2 py-1 rounded-md bg-white">
                    <p className="font-semibold text-red-500">{initials}</p>
                </div>
                <div className="flex flex-col">
                    {email && <p className="text-sm font-medium text-gray-700 leading-none">{email}</p>}
                </div>
            </div>
        </div>
    )
}
export default UserProfileBadge;