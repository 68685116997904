export const FORM_TITLE = "Business Details";
export const ORDER_FORM_TITLE = "Order Details";

export const BUTTON_TEXT = "View Details";

export const BUSINESS_FORM_FIELDS = {
  business_name: { type: "TEXT" },
  address: { type: "TEXT" },
  contact_person_name: { type: "TEXT" },
  contact_person_mobile_no: { type: "TEXT" },
  owner_name: { type: "TEXT" },
  owner_mobile_no: { type: "TEXT" },
  setup_category: { type: "RADIO", values: ["NEW", "OLD"] },
  business_category: {
    type: "SELECT",
    dropdownValues: [],
  },
};

export const BUSINESS_FORM_LABELS = {
  business_name: "Business Name",
  address: "Address",
  contact_person_name: "Person Name",
  contact_person_mobile_no: "Person Mobile Number",
  owner_name: "Owner Name",
  owner_mobile_no: "Owner Mobile Number",
  setup_category: "New Opening",
  business_category: "Category",
};

export const ORDER_FORM_LABELS = {
  status: "Order Status",
};

export const BUSINESS_TYPE = {
  NEW: "NEW",
  OLD: "OLD",
};

export const REACT_SELECT_SEPARATOR = {
  IndicatorSeparator: () => null
}
