import React from "react";

const Footer = () => {
  return (
    <footer className="p-4 h-full bg-white rounded-lg shadow md:flex md:items-center md:justify-between dark:bg-gray-800">
      <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
        © 2024 HORECA™
      </span>
      <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
        All Rights Reserved
      </span>
    </footer>
  );
};

export default Footer;
