import { useState, useEffect, createContext } from "react";
import * as Utils from "../Utils/index";

export const UserContext = createContext();

const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState("");
  const [loading, setLoading] = useState(true);

  const login = (user) => {
    Utils.setUser(user);
    setUser(user);
  };

  const logout = () => {
    Utils.removeCookie("user");
    setUser("");
  };

  const values = {
    user,
    setUser,
    login,
    logout,
  };

  useEffect(() => {
    const user = Utils.getUser();
    setUser(user);
    setLoading(false)
  }, []);

  if (!loading) {
    return <UserContext.Provider value={values}>{children}</UserContext.Provider>;
  }
};

export default UserContextProvider;
