import { useEffect, useState } from "react";
import { Common } from "../../../Components";
import { MasterLabels } from "../../../Constants/TableHeader";
import InfiniteScroll from "react-infinite-scroll-component";
import { MESSAGES } from "../../../Constants/Strings";
import * as businessCategoryService from "../../../Services/ordersService";
import toast from "react-hot-toast";
import { RiDeleteBin5Fill, RiEdit2Fill } from "react-icons/ri";
import {actions, renderField,menus } from "../../../Constants/MasterConstants";

const BusinessCategory = () => {
  const [isOpen, setIsOpen] = useState({ status: false, action: "" });
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [categoriesId, setCategoriesId] = useState(0);
  const [formInitialData, setFormInitialData] = useState({
    category: "",
    description: "",
  });
  const [pagination, setPagination] = useState({
    count: 0,
    offset: 0,
    limit: 10,
  });
  const tableColumns = menus.businessCategory["tableColumns"];

  const fieldTouched = {
    category: false,
    description: false,
  };


  const openPopUp = (action, id = 0, rowData = {}) => {
    setCategoriesId(id);
    switch (action) {
      case actions.add:
        setFormInitialData({
          category: "",
          description: "",
        });
        break;
      case actions.edit:
        setFormInitialData({
          category: rowData.category,
          description: rowData.description,
        });
        break;
      case actions.delete:
        setFormInitialData({});
        
        break;
    
      default:
        break;
    }
    setIsOpen({ status: true, action: action });
  };

  const handleError = (data) => {
    return {};
  };

  const updatePagination = (count) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      count: count,
      offset: pagination.offset + pagination.limit,
    }));
  };

  const updateEntity = (new_entity) => {
    setCategories((prevData) => {
      const combinedEntity = [...prevData, ...new_entity];
      const uniqueEntity = Array.from(
        new Map(combinedEntity.map((entity) => [entity.id, entity])).values()
      );
      return uniqueEntity;
    });
  };

  const fetchEntity = (menuEntity) => {
    if (pagination.offset > pagination.count) {
      setLoading(false);
      return;
    }
    businessCategoryService.getMasterList(menuEntity, pagination)
      .then((response) => {
        if (response.status === 200) {
          updatePagination(response.data.count);
          updateEntity(response.data.results);
        } else {
          toast.error(MESSAGES.FETCH_BUSINESS_CATEGORY_FAILURE);
        }
      })
      .catch(() => {
        toast.error(MESSAGES.FETCH_BUSINESS_CATEGORY_ERROR);
      })
      .finally(() => setLoading(false));
  };

  const renderFields = (key, rowData, entity_Id) => {
    if (key === "actions") {
      return (
        <div className="flex w-full gap-4 items-center">
          <RiEdit2Fill
            className={
              "text-primary text-2xl cursor-pointer hover:text-secondary transition duration-300"
            }
            onClick={() => {
              openPopUp(actions.edit, entity_Id, rowData);
            }}
          />
          <RiDeleteBin5Fill
            className={
              "text-red-500 text-2xl cursor-pointer hover:text-red-600 transition duration-300"
            }
            onClick={() => openPopUp(actions.delete, entity_Id)}
          />
        </div>
      );
    } 
    return rowData[key];
    
  };

  const onsubmit = (values) => {
    switch(isOpen.action){
      case actions.add:
        businessCategoryService.createMasterEntity(menus.businessCategory["url"], values)
        .then((res) => {
          if (res.status === 201) {
            toast.success(MESSAGES.BUSINESS_CATEGORY_ADD_SUCCESS);
            fetchEntity(menus.businessCategory["url"]);
          }
        })
        .catch((err) => {
          toast.error(MESSAGES.BUSINESS_CATEGORY_ADD_ERROR);
        });
        break;
      case actions.edit:
        businessCategoryService.editBusinessCategory(categoriesId, values)
        .then((res) => {
          if (res.status === 200) {
            toast.success(MESSAGES.BUSINESS_CATEGORY_EDIT_SUCCESS);
            setIsOpen({ status: false, action: "" });
            setPagination({
              count: 0,
              offset: 0,
              limit: 10,
            });
            fetchEntity(menus.businessCategory["url"]);
          }
        });
        break;
      case actions.delete:
        businessCategoryService.deleteBusinessCategory(categoriesId)
        .then((res) => {
          if (res.status === 204) {
            toast.success(MESSAGES.BUSINESS_CATEGORY_DELETE_SUCCESS);
            setCategories([]);
            setPagination({
              count: 0,
              offset: 0,
              limit: 10,
            });
            fetchEntity(menus.businessCategory["url"]);
          } else if (res.status === 500) {
            toast.error(MESSAGES.BUSINESS_CATEGORY_BOOKED_ERROR);
          }
        })
        .catch((err) => {
          toast.error(MESSAGES.BUSINESS_CATEGORY_DELETE_ERROR);
        });
        break;
         default:
    }
    setIsOpen({ status: false, action: "" });
  };

  const btnTitle = () => {
    switch (isOpen.action) {
      case actions.edit:
        return {btnTitle:"Save",message:""};
      case actions.delete:
        return {btnTitle:"Delete",message:MESSAGES.BUSINESS_CATEGORY_DELETE_CONFORMATION};
      default:
        break;
    }
    
  };
  useEffect(() => {
    setLoading(true);
    setPagination({
      count: 0,
      offset: 0,
      limit: 10,
    });
    fetchEntity(menus.businessCategory["url"]);
  }, [isOpen]);

  return (
    <div className="flex flex-col gap-4 mt-4 w-full md:w-4/5 lg:w-4/5  h-max md:h-[85vh] lg:h-[85vh]  p-6 bg-white shadow-md">
      <div className="w-full flex items-center justify-between ">
      <h2 className="text-secondary font-bold text-2xl">{menus.businessCategory["name"]}</h2>
        <Common.Button
          className="max-w-max bg-primary hover:bg-secondary"
          onClick={() => {openPopUp(actions.add);}}
        >
          {actions.add} {menus.businessCategory["name"]}
        </Common.Button>
      </div>
      {isOpen.status && (
        <div className="">
          <Common.GenericPopUpForm
            formData={formInitialData}
            renderField={renderField}
            formFieldLabels={MasterLabels}
            formTitle={isOpen.action+" " + menus.businessCategory["name"]}
            formFieldTouched={fieldTouched}
            onClose={() => {setIsOpen({ status: false, action: "" });}}
            isOpen={isOpen.status}
            handleData={(values) => {onsubmit(values);}}
            handleError={(data) => handleError(data)}
            className={{
              buttonClass: "mt-4 w-full flex justify-end",
              mainContainerclass:
                "p-8 bg-white rounded-md shadow-lg relative min-h-min",
              formclass: "p-2 flex flex-col w-full",
              inputclass: "w-full",
              cutBtnClass:
                "absolute top-0 right-0 m-4 text-xl cursor-pointer focus:outline-none",
            }}
            formContent={btnTitle()}
          />
        </div>
      )}

      {loading ? (
        <Common.Spinner />
      ) : (
        <InfiniteScroll
          dataLength={categories.length}
          next={() => {fetchEntity(menus.businessCategory["url"]);}}
          hasMore={pagination.offset <= pagination.count}
          scrollableTarget="scrollableDiv"
        >
          <div
            className="md:h-table_desk md:overflow-y-auto lg:overflow-y-auto mt-2 "
            id="scrollableDiv"
          >
            <Common.CommonTable
              className="border-2 bg-white"
              tableColumns={tableColumns}
              tableRows={categories}
              columnLabels={MasterLabels}
              renderField={renderFields}
            />
          </div>
        </InfiniteScroll>
      )}
    </div>
  );
};

export default BusinessCategory;
