import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import * as loginService from "../../../Services/loginService";
import * as Utils from "../../../Utils";
import * as Context from "../../../Context";
import * as routes from "../../../routes";
import toast from "react-hot-toast";
import { MESSAGES } from "../../../Constants/Strings";
import { ROLES } from "../../../Constants/Strings";

const LoginForm = () => {
  const [error, setError] = useState("");
  const { setUser } = useContext(Context.UserContext);
  const navigate = useNavigate();

  const userSchema = Yup.object({
    email: Yup.string().email(MESSAGES.INVALID_EMAIL).matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.com$/,
      MESSAGES.EMAIL_VALIDATION
    ).required(MESSAGES.REQUIRED),
    password: Yup.string()
      .min(5, MESSAGES.PASSWORD_VALIDATION)
      .required(MESSAGES.REQUIRED),
  });

  const clearForm = (values) => {
    values.email = "";
    values.password = "";
  };
  const navigateToSignup = () => {
    navigate(routes.SIGNUP);
  };
  const onSubmit = (values, { setSubmitting }) => {
    loginService
      .login(values)
      .then((response) => {
        if (response.data) {
          Utils.setUser(response.data);
          setUser(response.data);
          if (response.data.role === ROLES.ADMIN) {
            navigate(routes.ALL_ORDERS, { replace: true });
          } else if (response.data.role === ROLES.CUSTOMER) {
            navigate(routes.LIST_ORDERS, { replace: true });
          }
          toast.success(MESSAGES.LOGIN_SUCCESS);
        } else if (response.error) {
          const errorMsg = response.error.errors.error[0];
          setError(errorMsg);
          clearForm(values);
          toast.error(MESSAGES.LOGIN_FAILURE);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };
  return (
    <>
      <div className=" min-h-screen w-full flex lg:bg-slate-100 justify-center items-center">
        <div className="flex gap-2 flex-col w-4/5 h-full p-5 bg-white shadow-md items-center justify-center border rounded-lg border-gray ">
          <div className="flex flex-col">
            <p className="text-5xl text-center font-bold leading-relaxed drop-shadow-lg uppercase text-rose-900">
              HORECA
            </p>
            <p className="text-center">Hotels &nbsp; Restaurants &nbsp; Cafes</p>
            <p className="text-center text-2xl text-sky-800 mt-4 font-semibold">
              LOGIN
            </p>
          </div>
          <div className="w-full">
            <Formik
              initialValues={{ email: "", password: "" }}
              validationSchema={userSchema}
              onSubmit={onSubmit}
            >
              {({ isSubmitting }) => (
                <Form className="flex flex-col gap-2 w-full ">
                  <div className="w-full">
                    <label>Email</label>
                    <div className="w-full">
                      <Field
                        type="email"
                        name="email"
                        placeholder="Enter your name"
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 bg-gray-100  focus:outline-none focus:shadow-outline"
                      />
                      <ErrorMessage
                        name="email"
                        component="p"
                        className="text-red-500 text-xs italic"
                      />
                    </div>
                  </div>

                  <div>
                    <label>Password</label>
                    <div>
                      <Field
                        type="password"
                        name="password"
                        placeholder="Enter your password"
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 bg-gray-100 focus:outline-none focus:shadow-outline"
                      />
                      <ErrorMessage
                        name="password"
                        component="p"
                        className="text-red-500 text-xs italic"
                      />
                    </div>
                  </div>

                  <div className="flex flex-col gap-2 items-center justify-center">
                    <div className=" text-sm">
                      {error && (
                        <p className=" text-red-500 italic text-center">
                          {error}
                        </p>
                      )}
                    </div>
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className=" cursor-pointer w-full bg-blue-500 hover:bg-blue-700 text-white p-2 rounded focus:outline-none focus:shadow-outline"
                    >
                      Log In
                    </button>
                    <div className="flex items-center gap-2 justify-center text-center mt-2 text-sm text-secondary">
                      <div> Don't have an account?</div>
                      <div
                        className="font-bold text-md leading-6 text-secondary cursor-pointer"
                        onClick={navigateToSignup}
                      >
                        Sign Up
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginForm;
