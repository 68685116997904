import React, { useEffect, useState } from "react";

// formData => json of initial form data Key-DefaultValue pair
// renderField => json of key - value which declares input type(SELECT, DATE, TEXT) and input type meta info if needed e.g.  { "key": { "type": "SELECT", "dropdownValues": ["1", "2", "3"] } }
// isOpen => boolean - to open the form
// onClose => function method to close the form
// handleData => get calls on submit the form

const GenericPopUpForm = ({
  formData,
  renderField,
  isOpen,
  onClose,
  handleData,
  formTitle,
  formFieldLabels = null,
  handleError,
  formFieldTouched,
  className={},
  formContent={btnTitle:"Submit",message:""},
  message=""
}) => {
  const [updatedFormData, setUpdatedFormData] = useState(formData);
  const [isFieldTouch, setIsFieldTouch] = useState(formFieldTouched);
  const [error, setError] = useState({});

  const handleBlur = (e) => {
    const { name } = e.target;
    setIsFieldTouch({
      ...isFieldTouch,
      [name]: true,
    });
    let errors = handleError(updatedFormData);
    setError(errors);
  };

  const handleChange = (fieldName, e) => {
    setIsFieldTouch({
      ...isFieldTouch,
      [e.target.name]: true,
    });

    setUpdatedFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: e.target.value,
    }));
    let errors = handleError(updatedFormData);
    setError(errors);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let errors = handleError(updatedFormData);
    if (Object.entries(errors).length === 0) {
      handleData(updatedFormData);
    }
  };
  useEffect(() => {
    setUpdatedFormData(formData);
  }, [formData]);
  

  //CSS
  const default_css = className.inputclass || "grid mb-2 md:grid-cols-1 md:w-[40%]";
  const mainContainerclass = className.mainContainerclass || "bg-white p-6  rounded-md shadow-lg relative min-h-min max-h-[80vh] overflow-y-auto md:pb-14 md:mx-20";
  const formclass = className.formclass || "relative flex flex-col justify-between md:flex-row md:flex-wrap";
  const buttonClass = className.buttonClass || "mt-4 md:mt-0 md:absolute md:-bottom-10 md:right-0";
  const cutBtnClass = className.cutBtnClass || "absolute top-0 right-0 m-4 text-xl cursor-pointer focus:outline-none";
  
  const radio_css = `flex items-center gap-5 md:w-[40%]`;
  return (
    <div className="overflow-y-scroll ">
      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className={mainContainerclass}>
            <button
              onClick={onClose}
              className={cutBtnClass}
            >
              ❌
            </button>{" "}
            <h2 className="text-2xl text-black font-bold mb-6 text-center">
              {formTitle}
            </h2>
            {formContent.message? <h4>
              {formContent.message}
            </h4>: ""}
            <form
              onSubmit={(e) => handleSubmit(e)}
              className= {formclass}
            >
              {Object.entries(updatedFormData).map(
                ([fieldName, fieldValue]) => (
                  <div
                    key={fieldName}
                    className={
                      renderField[fieldName].type === "RADIO"
                        ? radio_css
                        : default_css
                    }
                  >
                    {renderField[fieldName].type === "HIDE" ? (
                      <></>
                    ) : (
                      <label
                        htmlFor={fieldName}
                        className="block text-lg font-medium text-gray-900 mb-1"
                      >
                        {formFieldLabels && formFieldLabels[fieldName]
                          ? formFieldLabels[fieldName]
                          : fieldName}
                      </label>
                    )}
                    {(() => {
                      switch (renderField[fieldName].type) {
                        case "SELECT":
                          return (
                            <div>
                              <select
                                id={fieldName}
                                name={fieldName}
                                value={updatedFormData[fieldName]}
                                onChange={(e) => handleChange(fieldName, e)}
                                required
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                              >
                                <option value="">Select</option>
                                {renderField[fieldName].dropdownValues?.map(
                                  (option, index) => {
                                    if (
                                      typeof option === "object" &&
                                      option !== null
                                    ) {
                                      // Option is an object
                                      return (
                                        <option key={index} value={option.id}>
                                          {formFieldLabels && option.category}
                                        </option>
                                      );
                                    } else {
                                      // Option is a string
                                      return (
                                        <option key={index} value={option}>
                                          {formFieldLabels &&
                                          formFieldLabels[option]
                                            ? formFieldLabels[option]
                                            : option}
                                        </option>
                                      );
                                    }
                                  }
                                )}
                              </select>
                            </div>
                          );

                        case "RADIO":
                          return (
                            <div className="flex gap-4">
                              {renderField[fieldName].values?.map(
                                (value, index) => (
                                  <div className="flex items-center gap-2">
                                    <input
                                      type="radio"
                                      value={value}
                                      name={fieldName}
                                      onChange={(e) =>
                                        handleChange(fieldName, e)
                                      }
                                     
                                    />
                                    <p>{value}</p>
                                  </div>
                                )
                              )}
                            </div>
                          );
                        case "DATE":
                          return (
                            <div>
                              <input
                                type="date"
                                value={updatedFormData[fieldName]}
                                onChange={(e) => handleChange(fieldName, e)}
                                className="p-2 w-full text-md sm:text-sm text-gray-900 border border-gray-300 rounded-md bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
                              />
                            </div>
                          );
                        case "TEXT":
                          return (
                            <div>
                              <input
                                type="text"
                                id={fieldName}
                                name={fieldName}
                                value={updatedFormData[fieldName]}
                                onBlur={(e) => {
                                  handleBlur(e);
                                }}
                                onChange={(e) => handleChange(fieldName, e)}
                                required
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                              />
                              <p className="text-red-500 h-5">
                                {error && isFieldTouch[fieldName] === true
                                  ? error[fieldName]
                                  : ""}
                              </p>
                            </div>
                          );
                          case "TEXTAREA":
                            return(
                              <div>
                                <textarea 
                                  id={fieldName}
                                  name={fieldName}
                                  value={updatedFormData[fieldName]}
                                  onBlur={(e) => {
                                    handleBlur(e);
                                  }}
                                  onChange={(e) => handleChange(fieldName, e)}
                                  required
                                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"  
                                  rows="4"
                                  cols="50"
                                >
  
                                </textarea>
                              </div>
                            );
                        case "DISABLED":
                          return (
                            <div>
                              <input
                                type="text"
                                id={fieldName}
                                name={fieldName}
                                disabled={true}
                                value={updatedFormData[fieldName]}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                required
                              />
                            </div>
                          );
                        case "HIDE":
                          return null;

                        
                        default:
                          return (
                            <p className="text-red-500 text-sm">
                              Form field input type is not defined
                            </p>
                          );
                      } 
                    })()}
                  </div>
                )
              )}
              <div className={buttonClass}>
                <button
                  type="submit" 
                  className="bg-primary hover:bg-secondary text-white font-bold py-2 px-4 rounded-md focus:outline-none focus:ring focus:border-secondary w-full md:w-auto"
                >
                  {formContent.btnTitle || "Submit"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default GenericPopUpForm;
