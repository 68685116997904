import React, { useState } from "react";
import * as Common from "../../Components/Common";
import SideBar from "./MasterComponent/Sidebar";
import ItemManager from "./MasterComponent/ItemManager";
import BusinessCategory from "./MasterComponent/BusinessCategoryManager";
import PurposeManager from "./MasterComponent/PurposeManager";
import { menus } from "../../Constants/MasterConstants";

const MasterSection = () => {
 
  const [activeTab, setActiveTab] = useState(Object.keys(menus)[0]);

  const renderTabContent = () => {
    switch (activeTab) {
      case Object.keys(menus)[0]:
        return <BusinessCategory />;
        
      case Object.keys(menus)[1]:
        return <ItemManager />;

      case Object.keys(menus)[2]:
        return <PurposeManager />;
      default:
    }
  };

  return (
    <div className="flex flex-col md:flex-row lg:flex-row gap-4 mt-16 w-full">
      <SideBar
        menus={menus}
        activeTab={activeTab}
        setActivetab={setActiveTab}
      />
      {renderTabContent()}
    </div>
  );
};

export default MasterSection;
