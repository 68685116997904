import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import InfiniteScroll from "react-infinite-scroll-component";
import * as Common from "../../Components/Common";
import { formatDate } from "../../Utils";
import * as ordersService from "../../Services/ordersService";
import * as routes from "../../routes";
import { MESSAGES } from "../../Constants/Strings";
import { OrderColumns, UserOrders } from "../../Constants/TableHeader";
import * as formContansts from "../../Constants/FormConstants";
import * as OrdersList from "../../Constants/OrderListConstants";

const ListOrders = () => {
  const [orderData, setOrderData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const [businessCategoryList, setBusinessCategoryList] = useState([]);
  const [formInitialData, setFormInitialData] = useState({
    business_name: "",
    address: "",
    contact_person_name: "",
    contact_person_mobile_no: "",
    owner_name: "",
    owner_mobile_no: "",
    setup_category: "",
    business_category: "",
  });

  let BUSINESS_FORM_FIELDS = {
    business_name: { type: "TEXT" },
    address: { type: "TEXT" },
    contact_person_name: { type: "TEXT" },
    contact_person_mobile_no: { type: "TEXT" },
    setup_category: { type: "RADIO", values: ["NEW", "OLD"] },
    owner_name: { type: "TEXT" },
    owner_mobile_no: { type: "TEXT" },
    business_category: {
      type: "SELECT",
      dropdownValues: businessCategoryList,
    },
  };
  const fieldTouched = {
    business_name: false,
    address: false,
    contact_person_name: false,
    contact_person_mobile_no: false,
    owner_mobile_no: false,
    owner_name: false,
    setup_category: false,
    business_category: false,
  };

  const [pagination, setPagination] = useState({
    count: 0,
    offset: 0,
    limit: 10,
  });
  
  const renderFields = (key, rowData, orderId) => {
    const value = rowData[key];
    if (key === OrdersList.KEYS.action) {
      return (
        <button
          className={"font-semibold text-primary py-3  hover:text-secondary"}
          onClick={() => {
            navigate(routes.CREATE_ORDERS.replace(":orderId", orderId), {
              state: { isEditable: false },
            });
          }}
        >
          {OrdersList.BUTTON_TITLE}
        </button>
      );
    } else {
      return value;
    }
  };

  const onSubmit = (values) => {
    ordersService.createOrder(values)
      .then((res) => {
        if (res.status === 201) {
          toast.success(MESSAGES.ORDER_CREATE_SUCCESS);
          navigate(routes.CREATE_ORDERS.replace(":orderId", res.data.id), {
            state: { isEditable: true },
          });
        }
      })
      .catch((err) => {
        toast.error(MESSAGES.ORDER_CREATE_FAILURE);
      });
  };

  const processOrders = (orders) => {
    return orders.map((order) => ({
      ...order,
      created: formatDate(order.created),
      updated: formatDate(order.updated),
    }));
  };

  const updateOrderData = (orders) => {
    setOrderData((prevData) => {
      const combinedOrders = [...prevData, ...orders];
      const uniqueOrders = Array.from(
        new Map(combinedOrders.map((order) => [order.id, order])).values()
      );
      return uniqueOrders;
    });
  };

  const updatePagination = (count) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      count: count,
      offset: pagination.offset + pagination.limit,
    }));
  };

  const fetchOrders = () => {
    if (pagination.offset > pagination.count) {
      setLoading(false);
      return;
    }

    ordersService.getOrders(pagination)
      .then((response) => {
        if (response.status === 200) {
          const orders = processOrders(response.data.results);
          updateOrderData(orders);
          updatePagination(response.data.count);
        } else {
          toast.error(MESSAGES.GENERAL_ERROR);
        }
      })
      .catch(() => {
        toast.error(MESSAGES.GENERAL_FETCH_ERROR);
      })
      .finally(() => setLoading(false));
  };

  const openPopUp = () => {
    const fetchBusinessCategory = () => {
      ordersService.getBusinessCategories()
        .then((res) => {
          setBusinessCategoryList(res.data.results);
        })
        .catch((err) => {
          toast.error(MESSAGES.ORDER_FETCH_ERROR);
        });
    };
    fetchBusinessCategory();
    setIsOpen(true);
  };

  const handleError = (data) => {
    let error = {};
    const mobilePattern = /^\d{10}$/;

    for (let property in data) {
      switch (property) {
        case "contact_person_mobile_no":
          if (!mobilePattern.test(data[property])) {
            error[property] = "Mobile number must be valid";
          } else {
            delete error.contact_person_mobile_no;
          }
          break;
        case "owner_mobile_no":
          if (!mobilePattern.test(data[property])) {
            error[property] = "Mobile number must be valid";
          } else {
            delete error.owner_mobile_no;
          }
          break;
        default:
          if (
            typeof data[property] != "object" &&
            data[property].trim() === ""
          ) {
            error[property] = "must contain at least 1 character";
          } else {
            delete error[property];
          }
      }
    }
    return error;
  };

  useEffect(() => {
    setLoading(true);
    fetchOrders();
  }, []);

  return (
    <div className="">
      <div className="flex justify-between items-center mt-20 mx-9 md:mx-9">
        <div className="text-xl md:text-3xl text-secondary font-semibold ">
          {OrdersList.FORM_TITLE}
        </div>
        <Common.Button className={"bg-primary hover:bg-secondary"} onClick={openPopUp}>Create New Order </Common.Button>
      </div>

      <div className="">
        {isOpen && (
          <Common.GenericPopUpForm
            formData={formInitialData}
            renderField={BUSINESS_FORM_FIELDS}
            formFieldLabels={formContansts.BUSINESS_FORM_LABELS}
            formTitle={formContansts.FORM_TITLE}
            formFieldTouched={fieldTouched}
            onClose={() => {
              setIsOpen(false);
            }}
            isOpen={isOpen}
            handleData={(businessDetails) => {
              onSubmit(businessDetails);
            }}
            handleError={(data) => handleError(data)}
          />
        )}
      </div>

      <div className="mx-8">
        {loading ? (
          <Common.Spinner />
        ) : (
          <InfiniteScroll
            dataLength={orderData.length}
            next={fetchOrders}
            hasMore={pagination.offset <= pagination.count}
            scrollableTarget="scrollableDiv"
          >
            <div
              className="md:h-table_desk mt-4 md:overflow-x-auto md:overflow-y-auto"
              id="scrollableDiv"
            >
              <Common.CommonTable
                className="border bg-white"
                tableColumns={UserOrders}
                tableRows={orderData}
                columnLabels={OrderColumns}
                renderField={renderFields}
              />
            </div>
          </InfiniteScroll>
        )}
      </div>
    </div> 
  );
};

export default ListOrders;
