export const HOME = "/";
export const MASTER = "/master";

export const LIST_USERS = "/allusers";

export const SIGNUP = "/signup";
export const LOGIN = "/login";

const ORDER_ID = "/:orderId";
export const LIST_ORDERS = "/listorders";
export const CREATE_ORDERS = "/createorder" + ORDER_ID;

export const ALL_ORDERS = "/allorders";
